import React, { useState, useEffect } from 'react';
import { Table, Radio, Input, Button, Spin, message, Modal } from 'antd';
import styles from '../src/css/Writting.module.scss'
import TabBar from './components/TabBar';
import { useNavigate, useParams } from 'react-router';
import { observer } from "mobx-react";
import Steps from './components/Steps';
import taskStore from './store/taskStore';
import Outline from './Outline';
import Draft from './Draft';
import Submission from './Submission';

function Writting() {

  const navigate = useNavigate()
  const params = useParams()
 
  const { confirm } = Modal;

  
  useEffect(() => {
    taskStore.fetchTask(params.id)
  }, [])
 
  const [messageApi, contextHolder] = message.useMessage();

  return (
    <>
      <TabBar title="Writting" subTitle={`Topic: ${taskStore.taskProfile ?  taskStore.taskProfile.topic : ''}`}/>
      <Spin spinning={taskStore.loading}>
        <div className={styles.contentWrapper}>
          <Steps step={taskStore.step} maxStep={taskStore.maxStep} onChange={taskStore.setStep} titleArr={["Step 1: Outline Preparation", "Step 2: Draft Writing", "Step 3: AI Feedback"]} />
          {
            taskStore.step === 1 && <Outline />
          }
          {
            taskStore.step === 2 && <Draft />
          }
          {
            taskStore.step === 3 && <Submission />
          }
        </div>
      </Spin>
    </>
  )
}

export default observer(Writting);