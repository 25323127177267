import React, { useState, useEffect } from 'react';
import { Table, Radio, Input, Button, Spin, message, Modal } from 'antd';
import styles from '../src/css/AuthorManage.module.scss'
import TabBar from './components/TabBar';
import { useNavigate, useParams } from 'react-router';
import { observer } from "mobx-react";
import EInput from './components/EInput';
import { PlusOutlined, CloseCircleOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { countWords } from './utils/utils';
import fileDownload from 'js-file-download'
import Score from './components/Score';
import { getTaskParticipants } from './utils/request';
import AiHelp from './components/AiHelp';
import CommentCard from './components/CommentCard';

function AuthorManage() {

 
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const [selectedRow, setSelectedRow] = useState(null)
  const [type, setType] = useState('')

  const navigate = useNavigate()
  const params = useParams()
 
  const { confirm } = Modal;
  
  useEffect(() => {
    fetchTask(params.id)
  }, [])

  const fetchTask = async (id) => {
    setLoading(true)
    const result = await getTaskParticipants({
      taskProfileId: id,
      role: 'author'
    })
    console.log(result.data)

    setData(result.data)

    setLoading(false)

  }

  const handleClickSubmission = (column) => {
    // setType('submission')
    // setSelectedRow(column)
    window.open(`/writting/${column.id}?showPrompt=true`, '_blank')
  }

  const handleClickRevision = (column) => {
    setType('revision')
    setSelectedRow(column)
  }


  const countFormatter = (val) => {
    const cnt = countWords(val)
    return <div className={styles.cntWord}>{cnt} words</div>;
  }
 
  const [messageApi, contextHolder] = message.useMessage();

  const profile = data[0] && data[0].taskProfile

  return (
    <>
      <TabBar title="Author Task Management" />
      <Spin spinning={loading}>
        <div className={styles.body}>
          {contextHolder}
          
          {
            profile && 
            <div className={styles.left}>

              <div className={styles.segTitle}>
                Topic: {profile.topic}
              </div>

              <div className={styles.seg}>
                <div className={styles.head}>
                  <div>Author Name</div>
                  <div>First Submission</div>
                  <div>Revision Submission</div>
                </div>
                {
                  data.map((item, index) => {
                    return (<div className={styles.tableRow}>
                      <div>{item.taskAuthor.name}</div>
                      <div>
                        {
                          item.submission.submitted ? <Button type={selectedRow && selectedRow.id === item.id && type === 'submission' ? 'primary' : 'default'} onClick={() => handleClickSubmission(item)}> View</Button> : '-'
                        }
                      </div>
                      <div>
                        {
                         item.revision.submitted ? <Button type={selectedRow && selectedRow.id === item.id && type === 'revision' ? 'primary' : 'default'} onClick={() => handleClickRevision(item)}> View </Button> : '-'
                        }
                      </div>
                    </div>)
                  })
                }
              </div>
              
            </div>
          }
          

          <div className={styles.right}>
            {
              selectedRow &&
              <div className={styles.titleTop}>
                <div className={styles.titleLeft}>
                  <div>
                    Author: {selectedRow.taskAuthor.name}
                  </div>
                  <div style={{marginLeft: '20px'}}>
                    Version: {type === 'submission' ? 'First Submission' : 'Revision Submission'}
                  </div>
                </div>
                <div className={styles.titleRight}>
                  {
                    type === 'submission' ?
                    <div>AI Score: <span className={styles.score}>{selectedRow.taskAuthor.aiScore}</span></div> : <div>Reviewer Score: <span className={styles.score}>{selectedRow.taskAuthor.partnerScore}</span></div>
                  }
                </div>
              </div>
            }
            {
              selectedRow && type=='submission' &&
              <div>
                 <div className={[styles.seg, styles.rightSeg].join(' ')}>
                    <div className={styles.contentTitle}>
                      {
                        selectedRow.submission.title 
                      }
                    </div>
                    <div className={styles.content}>
                      {
                        selectedRow.submission.content
                      }
                    </div>
                    <div>{countFormatter(selectedRow.submission.content)}</div>
                </div>
                <div className={styles.aiHelp}>
                  <div className={styles.ai}>
                    <AiHelp aiHelps={selectedRow.outline.aiHelps} showPrompt/>
                  </div>

                  <div className={styles.ai}>
                    <AiHelp aiHelps={selectedRow.draft && selectedRow.draft.aiHelps || []} showPrompt/>
                  </div>
                </div>

                <div className={styles.aiHelp}>
                  <div className={styles.ai}>
                    <AiHelp aiHelps={selectedRow.submission.aiHelps} showPrompt/>
                  </div>
                 
                </div>
              </div>
            }

          {
              selectedRow && type=='revision' &&
              <div>
                 <div className={[styles.seg, styles.rightSeg].join(' ')}>
                    <div className={styles.contentTitle}>
                      {
                        selectedRow.revision.title 
                      }
                    </div>
                    <div className={styles.content}>
                      {
                        selectedRow.revision.content
                      }
                    </div>
                    <div>{countFormatter(selectedRow.revision.content)}</div>
                </div>

                <div style={{marginTop: '40px'}}>
                      {
                        selectedRow.reviews.map((item, index) => {

                          return (
                            <CommentCard review={item} title={`${item.taskReviewer.user.name}`}/>
                          )
                        })
                      }
                  
                </div>
              </div>
            }

          </div>
        </div>
      </Spin>
    </>
  )
}

export default observer(AuthorManage);