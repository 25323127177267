import React from 'react'
import styles from '../css/CommentCard.module.scss'
import ReviewerTag from '../img/reviewer-tag.png'
import { Input } from 'antd'
import Score from './Score'
import { observer } from 'mobx-react'
import AuthorTag from '../img/author-tag.png'
import userStore from '../store/userStore'

const { TextArea } = Input

 class RevisionCard extends React.Component {

  constructor(props) {
    super(props)
  }


  render() {
    const { review, index, isMy, disabled, title, showAiScore } = this.props
    if (!review || !review.submitted) return null

    return (
      <div>
        <div className={styles.card}>
          <img className={[styles.tag, isMy ? styles.myTag : ''].join(' ')} src={ReviewerTag} />

          <div className={styles.title}>{ title }</div>
          {
            showAiScore && <div className={styles.ai}>AI Score: <span className={styles.score}>{review.totalScore}</span></div>
          }
          <div className={styles.content}>
            <div className={styles.scores}>
              <div>Content: {review.contentScore} points</div>
              <div>Organization: {review.organizationScore} points</div>
              <div>Language: {review.languageScore} points</div>
            </div>
            <div className={styles.commentTitle}>Comment:</div>
            <div className={styles.comment}>{review.comment}</div>
          </div>
        </div>

        {
          review.authorResponse && 
          <div className={styles.othersCard}>
                  <img className={styles.tag} src={AuthorTag} />
                  <div className={styles.title}>Author response</div>
                  <div className={styles.content}>
                    <div className={styles.titleTop}>Your comments have received an author score of <span className={styles.score}>{review.authorScore}</span></div>
                    <div className={styles.commentTitle}>The author’s response:</div>
                    <div className={styles.comment}>{review.authorResponse}</div>
                    
                  </div>
                </div>
        }
        

      </div>
    )
  }
  
}

export default observer(RevisionCard);