import { action, makeAutoObservable } from "mobx";
import { getTaskDetail, saveTaskRevision} from "../utils/request";
import { create, persist } from 'mobx-persist'


// Todo 總數據源
class RevisionStore {

  loading = false
  aiLoading = false

  reviews = []
  taskProfile = {}
  revision = {}
  id = null
  taskAuthor = {}
  submission = {}

  constructor() {
    makeAutoObservable(this);
  }

  fetchTask = async (id) => {
    this.loading = true
    const res = await getTaskDetail(id)
    const task = res.data

    this.taskProfile = task.taskProfile
    this.reviews = task.reviews

    this.revision = task.revision
    this.taskAuthor = task.taskAuthor
    this.submission = task.submission
    this.id = task.id

    this.loading = false
  }

  saveRevision = async () => {

    console.log(this.reviews)
    
    const reviews = this.reviews.map(item => {
      return {
        reviewId: item.id,
        authorScore: item.authorScore,
        authorResponse: item.authorResponse,
      }
    })

    console.log(reviews, '!!')

    const result = await saveTaskRevision({
      taskId: this.id,
      title: this.revision.title,
      content: this.revision.content,
      reviews,
    })

    return result
  }
 
  submitRevision = async () => {

    const reviews = this.reviews.map(item => {
      return {
        reviewId: item.id,
        authorScore: item.authorScore,
        authorResponse: item.authorResponse,
      }
    })


    const result = await saveTaskRevision({
      taskId: this.id,
      title: this.revision.title,
      content: this.revision.content,
      submitted: true,
      reviews
    })

    this.revision = result.data.revision
    return result
  }

  evaluateComment = async () => {
    
  }
}


const schema = {
  
  topic: true,
  statement: true,
  requirement: true,
  
}


const hydrate = create({
  storage: localStorage,   // or AsyncStorage in react-native.
  jsonify: true  // if you use AsyncStorage, here shoud be true
})

const revisionStore = persist(schema)(new RevisionStore())

hydrate('revisionStore', revisionStore).then(() => console.log('revisionStore has been hydrated'))

export default revisionStore;
