import React, { useRef, useEffect } from 'react';
import { Button } from 'antd';
import styles from '../css/ReviewAiHelp.module.scss'
import Eva from '../img/eva.png'
import QuestionArrow from '../img/question-arrow.png'

export default (props) => {
    const { aiHelps, lBtn, rBtn, showBtns, hideTitle, full } = props

    const messagesEndRef = useRef(null)

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollTo({ behavior: "smooth", top: messagesEndRef.current.scrollHeight})
    }

    useEffect(() => {
        scrollToBottom()
    }, [aiHelps]);

    return (
        <div className={styles.wrapper}>
            <div>
                {!hideTitle && <div className={styles.title}>AI Help</div>}
                <div className={[styles.contentWrapper, full? styles.submitted: '' ].join(' ')} ref={messagesEndRef}>
                    {
                        aiHelps.map((item) => {
                            return (
                                <>
                                    {
                                        item.role === 'user' && 
                                        <div className={styles.question}>
                                            {item.showQuestion}
                                            <img className={styles.questionArrow} src={QuestionArrow}/>
                                        </div>
                                    }
                                    {
                                        (item.role === 'assistant' || item.role === 'system') &&
                                        <div className={styles.answerWrapper}>
                                            <img className={styles.eva} src={Eva}/>
                                            <div>
                                                <div className={styles.name}>Eva</div>
                                                <div className={styles.arrow}> </div>
                                                <div className={styles.content}>
                                                    {item.content}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </>
                            )
                        })
                    }
                </div>
            </div>
            {showBtns && 
                <div className={styles.bottom}>
                    {
                        lBtn && <div className={styles.btn} onClick={lBtn.onClick}>
                            <div>{lBtn.title}</div>
                            <div>{lBtn.subTitle}</div>
                        </div>
                    }
                    {
                    rBtn && <div className={styles.btn} onClick={rBtn.onClick}>
                            <div>{rBtn.title}</div>
                            <div>{rBtn.subTitle}</div>
                        </div>
                    }
                </div>
            }
        </div>
    )
}
