import { action, makeAutoObservable } from "mobx";
import { createTask, getClassUsers } from "../utils/request";
import { create, persist } from 'mobx-persist'


// Todo 總數據源
class TaskProfileStore {
  users = []
  loading = false
  topic = ''
  statement = ''
  requirement = ''

  assignment = []
  countMap = {}

  classId = ''

  constructor() {
    makeAutoObservable(this);
  }

  async fetchUsers(classId) {
    this.classId = classId
    this.loading = true
    const data = await getClassUsers(classId)
    this.users = data.data.map(item => {
      return {
        id: item.id,
        name: item.name,
        role: '',
      }
    })
    this.loading = false
    return data.data
  }

  
  setCountMap() {
    this.users.filter(item => item.role === 'reviewer').forEach(item => {
      this.countMap[item.id] = 0
    })
    this.assignment.forEach(item => {
      item.reviewers.forEach(reviewer => {
        this.countMap[reviewer.id] += 1
      })
    })
  }
  
  setRole(index, role) {
    console.log(this.users, index, role)
    this.users[index].role = role
  }

  submit(topic, statement, requirement) {
    this.topic = topic
    this.statement = statement
    this.requirement = requirement

    this.resetAssign()
  }

  resetAssign() {
    const authors = this.users.filter(item => item.role === 'author')

    this.assignment = authors.map(item => {
      return {
        author: item,
        reviewers: []
      }
    })
    this.setCountMap()
  }

  setAssign(assignIndex, reviewers) {
    this.assignment[assignIndex].reviewers = reviewers
    this.setCountMap()
  }

  delete(assignIndex, index) {
    this.assignment[assignIndex].reviewers.splice(index, 1)
    this.setCountMap()
  }

  async commit() {
    const data = {
      topic: this.topic,
      class: this.classId,
      statement: this.statement,
      requirement: this.requirement,
      assignments: this.assignment.map(item => {
        return {
          author: item.author.id,
          reviewers: item.reviewers.map(item => item.id)
        }
      }),
      reviewers: this.users.filter(item => item.role === 'reviewer').map(item => item.id),
      authors: this.users.filter(item => item.role === 'author').map(item => item.id),
    }
    
    const result = await createTask(data)
    return result
    
  }

  autoAssign(value) {
    this.resetAssign()
    const num = Number(value)

    console.log(num)

    const authorCount = this.users.filter(item => item.role === 'author').length
    const reviewers = this.users.filter(item => item.role === 'reviewer')
    const allReviewers = []
    for(let i = 0; i < num * authorCount; i++) {
      const index = i % reviewers.length
      allReviewers.push(reviewers[index])
    }

    for(let i = 0; i < num; i++) {
      let origin = [...Array(authorCount).keys()]
      origin.sort(() => Math.random() - 0.5);
      for(let j = 0; j < authorCount; j++) {
        let assign = this.assignment[origin[j]]
        for(let k = 0; k < allReviewers.length; k++) {
          const include = assign.reviewers.filter(item => item.id === allReviewers[k].id).length

          if (include > 0) {
            continue
          } else {
            assign.reviewers.push(allReviewers[k])
            allReviewers.splice(k, 1)
            break
          }
        }
      }
    }

    if(allReviewers.length > 0) {
      this.autoAssign(value)
    } else {
      this.setCountMap()
    }
  }
}


const schema = {
  users: {
    type: 'list',
    schema: {
      id: true,
      name: true,
      role: true,
    }
  },
  countMap: {
    type: 'object',
  },
  assignment: {
    type: 'list',
    schema: {
      author: {
        type: 'object',
        schema: {
          id: true,
          name: true,
          role: true,
        }
      },
      reviewers: {
        type: 'list',
        schema: {
          id: true,
          name: true,
          role: true,
        }
      },
    }
  },
  topic: true,
  statement: true,
  requirement: true,
  
}


const hydrate = create({
  storage: localStorage,   // or AsyncStorage in react-native.
  jsonify: true  // if you use AsyncStorage, here shoud be true
})

const taskProfileStore = persist(schema)(new TaskProfileStore())

hydrate('taskProfileStore', taskProfileStore).then(() => console.log('taskProfileStore has been hydrated'))

export default taskProfileStore;
