import React from 'react';
import styles from '../css/ShowPoints.module.scss';
import Coin from '../img/coin.png'
import Close from '../img/close.png'
import Leaderboard from '../img/leader-board.png'
import { Spin } from 'antd';
import { getLeaderBoard } from '../utils/request';

export default class ShowPoints extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      currentTab: 'classBoard',
      authors: undefined,
      reviewers: undefined,
    }
    this.fetchData()
  }

  fetchData = async () => {
    this.setState({loading: true})
    const data = await getLeaderBoard()
    this.setState({
      authors: data.data.authors,
      reviewers: data.data.reviewers,
    })
    this.setState({loading: false})

  }

  render() {

    const renderRankings = (showData, key) => {
      const { loading } = this.state 
      return (
        <div className={styles.rankContent}>
          <div className={styles.rankHead}>
            <div>Ranking</div>
            <div>Name</div>
            <div>Total Score</div>
          </div>
          <Spin spinning={loading} style={{marginBottom: 20}}>
            {
              showData && showData.map((item, i) => {
                return  <div className={styles.rankItem} key={i}>
                  
                  {
                    i < 3 ? <img src={require(`../img/Level${i+1}.png`)} className={styles.rankImg}/> :  <div className={styles.rankIndex}>{i+1}</div> 
                  }

                  <div className={styles.rankName}>{item.name}</div>
                  <div className={styles.rankScore}> {item[key]}</div>
                </div>
              })
            }
          </Spin>
        </div>
      )
    }

    return (
      <div className={styles.body}>
        <img src={Close} className={styles.close} onClick={this.props.hideModal()}/>

        <div className={styles.title}>
          <img src={Leaderboard} className={styles.leaderboard}/>
        </div>

        <div className={styles.content}>
          <div className={styles.rank}>
            <div className={styles.rankTitle}>Best Author in Your Class</div>
            <div className={styles.rightContent}>
              {
                renderRankings(this.state.authors, 'authorScore')
              }
            </div>
            <div className={styles.hint}>Total Score = number of author tasks completed x 0.3 + average score of author tasks completed x 0.7</div>
          </div>
          <div className={styles.rank}>
            <div className={styles.rankTitle}>Best Reviewer in Your Class</div>
            <div className={styles.rightContent}>
              {
                renderRankings(this.state.reviewers, 'reviewerScore')
              }
            </div>
            <div className={styles.hint}>Total Score = number of reviewer tasks completed x 0.3 + average score of reviewer tasks completed x 0.7</div>
          </div>
        </div>
      </div>
    )
  }
  
}
