import React, { useState, useEffect } from 'react';
import { Table, Radio, Input, Button, Spin, message, Modal } from 'antd';
import styles from '../src/css/CreateTask.module.scss'
import TabBar from './components/TabBar';
import { useNavigate, useParams } from 'react-router';
import taskProfileStore from './store/taskProfileStore';
import { observer } from "mobx-react";
import { getTaskProfileDetail, updateTaskProfile } from './utils/request';

function EditTask() {

  const navigate = useNavigate()
  const params = useParams()
  const [topic, setTopic] = useState('')
  const [statement, setStatement] = useState('')
  const [requirement, setRequirement] = useState('')
  const [users, setUsers] = useState([])
  const { confirm } = Modal;
  const [loading, setLoading] = useState(false)
  const [commitLoading, setCommitLoading] = useState(false)

  const fetchTaskProfileDetail = async () => {
    setLoading(true)
    const result = await getTaskProfileDetail(params.id)
    console.log(result)
    setTopic(result.data.topic)
    setRequirement(result.data.requirement)
    setStatement(result.data.statement)
    setUsers(result.data.taskParticipants)
    setLoading(false)
  }

  useEffect(() => {
   fetchTaskProfileDetail()
  }, [])
      
  const { TextArea } = Input;
  const [messageApi, contextHolder] = message.useMessage();

  const authorCount = users && users.filter(item => item.role === 'author').length
  const reviewerCount = users && users.filter(item => item.role === 'reviewer').length


  const commit = async () => {
    setCommitLoading(true)
    const result = await updateTaskProfile({
      taskProfileId: params.id,
      topic,
      requirement,
      statement,
    })
    setCommitLoading(false)
    messageApi.open({
      type: 'success',
      content: 'Update successfully',
    });
  }

  const next = () => {
    if(!topic) {
      messageApi.open({
        type: 'error',
        content: 'please input the topic',
      });
      return 
    } else if(!statement) {
      messageApi.open({
        type: 'error',
        content: 'please input the statement',
      });
      return 
    }
    else if(!requirement) {
      messageApi.open({
        type: 'error',
        content: 'please input the requirement',
      });
      return 
    }

    confirm({
      title: 'Attention',
      icon: null,
      content: 'Are you sure to commit your changes?',
      bodyStyle: {
        textAlign: 'center'
      },
      onOk() {
        commit()
      }
    })
    
  }

  const cancel = () => {
    navigate(-1)
  }

  return (
    <>
      <TabBar title="Task Edit"/>
      {contextHolder}
      <div className={styles.page}>
        <div className={styles.left}>
          <div className={styles.title}>Topic</div>
          <Input placeholder="please input the topic" value={topic} onChange={e => setTopic(e.target.value)}/>

          <div className={styles.title}>Task Instruction</div>
          <div className={styles.contentWrapper}>

            <div className={styles.state}>Statement</div>
            <TextArea
              bordered={false} 
              className={styles.textarea}
              placeholder="please input the statement"
              rows={4}
              value={statement}
              onChange={e => setStatement(e.target.value)}
            />

            <div className={styles.state}>Requirement</div>
            <TextArea
              bordered={false} 
              className={styles.textarea}
              placeholder="please input the requirement"
              rows={14}
              value={requirement}
              onChange={e => setRequirement(e.target.value)}
            />

          </div>
        </div>
        
        <div className={styles.right}>
          <div className={styles.title}>Role Assignment</div>
          
          <div className={styles.table}>
            <div className={styles.head}>
              <div>Name</div>
              <div>Author({authorCount})</div>
              <div>Reviewer({reviewerCount})</div>
            </div>
            <Spin spinning={loading}>
            {
              users && users.map((user, index) => {
                return <div className={styles.row}>
                    <div className={styles.name}>
                    {index+1}. {user.name}
                    </div>
                    <Radio value="author" className={styles.radio} checked={user.role === 'author'} disabled></Radio>
                    <Radio value="reviewer" className={styles.radio} checked={user.role === 'reviewer'}  disabled></Radio>
                  </div>
              })
            }
            </Spin>
          </div>
        </div>

       
      </div>
      <div className={styles.bottom}>
          <Button type="primary" className={styles.nextBtn} loading={commitLoading} onClick={next}>Update</Button>
          <Button className={styles.btn} disabled={commitLoading} onClick={cancel}>Cancel</Button>
      </div>
      
    </>
  )
}

export default observer(EditTask);