import axios, {isCancel, AxiosError} from 'axios';
import { message } from 'antd'
import userStore from '../store/userStore';

axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token')
    console.log('token', token)
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config;
  }, function (error) {
    // Do something with request error
    console.log(error)
    return Promise.reject(error);
});



axios.interceptors.response.use(function (response) {
    if (response.data) {
      if (response.data && response.data.code === 401) {
        console.log('未登录')
        localStorage.removeItem('token')
        if (window.location.pathname !== '/login') {
          window.location.href = '/login'
        }
        // TODO: redirect to login page
        // throw new Response("", { status: 401 });
        // message.error(response.data.msg)
        return Promise.reject('未登录');
      }
      if (response.data.code && response.data.code !== 0) {
        console.log('后台报错', response)
        message.error(response.data.msg)
        return Promise.reject(response.data.msg);
      }
      if (response.data && response.data.data && response.data.data.addCoin) {
        message.open({
          type: 'success',
          content: `Congratulations! You have earned ${response.data.data.addCoin} more A-coins!`
        })
      }
    }
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data;
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.status === 401) {
        console.log('未登录')
        localStorage.removeItem('token')
        if (window.location.pathname !== '/login') {
          window.location.href = '/login'
        }
        // TODO: redirect to login page
        // throw new Response("", { status: 401 });
        // redirect('login')
    }
    return Promise.reject(error);
  });

  userStore.refresh()

  export async function userLogin(data) {
    const result = await axios.post('/v1/auth/login', data)
    if (result.code === 0) {
        // 登录成功
        localStorage.setItem('token', result.data.access_token)
        const user = await userStore.refresh()
        return user
    }
    return result
  }

  export function getUserIssues() {
    return axios.get('/v1/issue/getUserIssues')
  }

  export function getIssueCountries(data) {
    return axios.post('/v1/country/getIssueCountries', data)
  }

  export function getCountryIdeas(data) {
    return axios.post('/v1/idea/getCountryIdeas', data)
  }

  export function createLike(data) {
    return axios.post('/v1/like/createLike', data)
  }

  export function cancelLike(data) {
    return axios.post('/v1/like/cancelLike', data)
  }
  
  export function getTestProfiles() {
    return axios.get('/v1/test/getTestProfiles')
  }

  export function getTestAnswers(data) {
    return axios.post('/v1/test/getTestAnswers', data)
  }

  export function getTestQuestions(data) {
    return axios.post('/v1/test/getTestQuestions', data)
  }

  export async function submitTestAnswer(data) {
    const result = await axios.post('/v1/test/submitTestAnswer', data)
    userStore.refresh()
    return result
  }

  export function getLeaderBoard() {
    return axios.get('/v1/auth/getLeaderboard')
  }

  export function getProfile() {
    return axios.get('/v1/auth/profile')
  }

  export async function createComment(data) {
    const result = await axios.post('/v1/comment/createComment', data)
    userStore.refresh()
    return result
  }

  export function joinCountry(data) {
    return axios.post('/v1/country/joinCountry', data)
  }

  export async function createIdea(data) {
    const result = await axios.post('/v1/idea/createIdea', data)
    userStore.refresh()
    return result
  }

  export function getProposal(data) {
    return axios.post('/v1/proposal/getProposal', data)
  }

  export function getMyProposal(data) {
    return axios.post('/v1/proposal/getMyProposal', data)
  }

  export function saveProposal(data) {
    return axios.post('/v1/proposal/saveProposal', data)
  }

  export async function submitProposal(data) {
    const result = await axios.post('/v1/proposal/submitProposal', data)
    userStore.refresh()
    return result
  }

  export function createProposal(data) {
    return axios.post('/v1/proposal/createProposal', data)
  }

  export function downloadMyProposal(data) {
    return axios.post('/v1/proposal/downloadMyProposal', data)
  }

  export function getEvaluationList(data) {
    return axios.post('/v1/evaluation/getEvaluationList', data)
  }

  export async function submitEvaluation(data) {
    const result = await axios.post('/v1/evaluation/submitEvaluation', data)
    userStore.refresh()
    return result
  }

  export function modifyPassword(data) {
    return axios.post('/v1/auth/modifyPassword', data)
  }

  export function getClass() {
    return axios.get('/v1/class/index')
  }

  export function createUser(data) {
    return axios.post('/v1/auth/createUser', data)
  }

  export function getCurrentUser(data) {
    return axios.post('/v1/auth/getCurrentUser', data)
  }

  export function updateUserStatus(data) {
    return axios.post('/v1/auth/updateStatus', data)
  }

  export function getUserDataReq(data) {
    return axios.post('/v1/auth/getUserData', data)
  }

  export function getUserKnowledgeData(data) {
    return axios.post('/v1/auth/getUserKnowledgeData', data)
  }

  export function getProposalWritting(data) {
    return axios.post('/v1/auth/getProposalWrittingData', data)
  }

  export function getClassIssues(data) {
    return axios.post('/v1/country/getClassIssues', data)
  }

  export function updateIssueAvailable(data) {
    return axios.post('/v1/country/updateAvailable', data)
  }

  export function updateMaxMemberCount(data) {
    return axios.post('/v1/country/updateMaxMemberCount', data)
  }

  export function getUserDetail(data) {
    return axios.post('/v1/auth/getUserDetail', data)
  }

  export function getTaskProfiles(classId) {
    return axios.get('/v1/task/getTaskProfiles', {
      params: {
        class: classId
      }
    })
  }

  export function getTaskProfileDetail(id) {
    return axios.get('/v1/task/getTaskProfileDetail', {
      params: {
        id
      }
    })
  }

  export function updateTaskProfile(data) {
    return axios.post('/v1/task/updateTaskProfile', data)
  }


  export function getClassUsers(classId) {
    return axios.get('/v1/class/getUserByClass', {
      params: {
        class: classId
      }
    })
  }

  export function createTask(data) {
    return axios.post('/v1/task/createTask', data)
  }

  export function getUserTasks() {
    return axios.get('/v1/task/getMyTasks')
  }

  export function getTaskDetail(id) {
    return axios.get('/v1/task/getTaskDetail', {
      params: {
        id
      }
    })
  }

  export function getSuggestClaim(data) {
    return axios.post('/v1/task/suggestSubclaim', data)
  }

  export function getSuggestCounterargument(data) {
    return axios.post('/v1/task/suggestCounterargument', data)
  }

  export function saveOutline(data) {
    return axios.post('/v1/task/saveOutline', data)
  }

  export function saveDraft(data) {
    return axios.post('/v1/task/saveDraft', data)
  }

  export function saveSubmission(data) {
    return axios.post('/v1/task/saveSubmission', data)
  }

  export function getEvaluateSubmission(data) {
    return axios.post('/v1/task/evaluateSubmission', data)
  }

  export function getTaskReviewDetail(taskId) {
    return axios.get('/v1/task/getTaskReviewDetail', {
      params: {
        taskId
      }
    })
  }

  export function evaluateReviewComment(data) {
    return axios.post('/v1/task/evaluateReviewComment', data)
  }

  export function submitTaskReview(data) {
    return axios.post('/v1/task/submitTaskReview', data)
  }

  export function saveTaskRevision(data) {
    return axios.post('/v1/task/saveRevision', data)
  }

  export function getTaskParticipants(data) {
    return axios.get('/v1/task/getTaskParticipants', {
      params: data
    })
  }