import React, { useState, useEffect } from 'react';
import { Table, Radio, Input, Button, Spin, message, Modal } from 'antd';
import styles from '../src/css/Writting.module.scss'
import TabBar from './components/TabBar';
import { useNavigate, useParams } from 'react-router';
import taskStore from './store/taskStore';
import { observer } from "mobx-react";
import EInput from './components/EInput';
import { PlusOutlined, CloseCircleOutlined, ArrowRightOutlined } from '@ant-design/icons';
import AiHelp from './components/AiHelp';
import { countWords } from './utils/utils';

function Draft() {

 
  const { TextArea } = Input;
  const { confirm } = Modal;
  const [saveLoading, setSaveLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)

  const showPrompt = window.location.href.indexOf('showPrompt') > 0

  const disabled = taskStore.draft.submitted
  
  useEffect(() => {

  }, [])

  // const handleDeleteOutline = (index) => {
  //   messageApi.confirm({
  //     title: 'Are you sure to delete this subclaim?',

  //   })
  // }

 

  const saveDraft = async () => {
    setSaveLoading(true)
    await taskStore.saveTaskDraft()
    messageApi.open({
      type: 'success',
      content: 'Saved successfully',
    });
    setSaveLoading(false)
  }

  const submitDraft = async () => {
    // 校验
    const words = countWords(taskStore.draft.content)
    if(!taskStore.draft.title) {
      messageApi.open({
        type: 'warning',
        content: 'Please input a title for your essay',
      });
      return
    }
    if (words < 300) {
      messageApi.open({
        type: 'warning',
        content: 'You need to write at least 300 words.',
      });
      return
    } 

    if (words > 500) {
      messageApi.open({
        type: 'warning',
        content: 'You need to write at most 500 words.',
      });
      return
    }

    // 二次确认
    confirm({
      title: 'Attention',
      icon: null,
      content: 'You won’t be able to make changes to this page if you proceed to the next step. Are you sure you want to proceed?',
      bodyStyle: {
        textAlign: 'center'
      },
      onOk() {
        submit()
      }
    })
  }

  const  submit = async () => {
    setSubmitLoading(true)
    await taskStore.submitTaskDraft()
    setSubmitLoading(false)
  }

  const countFormatter = (val) => {
    const cnt = countWords(val.value)
    return <div className={styles.cntWord}>{cnt} words</div>;
  }
      
 
  const [messageApi, contextHolder] = message.useMessage();

  return (
    <div className={styles.body}>
      {contextHolder}
      <div className={styles.content}>
        <div className={styles.seg}>
          <EInput disabled={disabled} width={40} onChange={value => taskStore.draft.title = value} value={taskStore.draft.title} label="Title" placeholder="Please input a title for your essay"/>
        </div>

        <div className={styles.seg}>
          <TextArea disabled={disabled} onChange={(e) => taskStore.draft.content = e.target.value} style={{resize: 'none'}} value={taskStore.draft.content} className={styles.draftTextArea} bordered={false} showCount={{ formatter: countFormatter }}/>
        </div>
      </div>
     
      <Spin spinning={taskStore.aiLoading}>
        <div className={styles.rightContent}>
          <div className={styles.ai}>
              <AiHelp  showPrompt={showPrompt} full={true} aiHelps={taskStore.draft.aiHelps} hideTitle={true} />
          </div>

          {
            !disabled &&
            <div className={styles.btns}>  
              <Button className={styles.btn} loading={saveLoading} size='large' type="primary" onClick={saveDraft}>Save</Button>
              <Button className={styles.btn} loading={submitLoading} size='large' type="primary" onClick={submitDraft}>Proceed <div className={styles.iconDiv}><ArrowRightOutlined className={styles.icon} /></div></Button>
            </div>
          }
        </div>
      </Spin>

     
    </div>
  )
}

export default observer(Draft);