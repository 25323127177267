import React, { useState, useEffect } from 'react';
import { Table, Select, Switch, Button, Spin } from 'antd';
import { getClass, getTaskProfiles } from './utils/request';
import { PlusOutlined } from '@ant-design/icons'
import styles from '../src/css/CRM.module.scss'
import TabBar from './components/TabBar';
import TaskProfileItem from './components/TaskProfileItem';
import { useNavigate } from 'react-router';

function CRM() {

  const [classInfo, setClassInfo] = useState()
  const [classId, setClassId] = useState()
  const [taskData, setTaskData ] = useState([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    getClassInfo()
  }, [])

  useEffect(() => {
    if (!classId) return
    fetchTaskProfiles()
  }, [classId])

  const fetchTaskProfiles = async () => {
    setLoading(true)
    const data = await getTaskProfiles(classId)
    setTaskData(data.data)
    setLoading(false)
  }

  const getClassInfo = async () => {
    const classInfo = await getClass()
    setClassInfo(classInfo.data)
  }

  const classOptions = []
  classInfo && classInfo.map((item) => {
    classOptions.push({
      value: item.name,
      label: item.name
    })
  })



  const jumpCreateTask = () => {
    navigate(`/create-task/${classId}`)
  }
  
  return (
    <>
      <TabBar title="Teacher Main Page" />
      <div className={styles.page}>
        <div className={styles.selectGroup}>
          <div className={styles.selectTitle}>Class Selection</div>
          <Select
            options={classOptions}
            className={styles.selector}
            onChange={(value) => setClassId(value)}
            value={classId}
          />
        </div>

        <div className={styles.head}>
          <div className={styles.title}>Task Management</div>

          <Button onClick={jumpCreateTask} type='primary' icon={<PlusOutlined />} className={styles.addButton}  disabled={!classId}>Create a new task</Button>
        </div>

        <Spin spinning={loading}>
          {
            taskData && taskData.map((item) => {
              return <TaskProfileItem taskProfileId={item.id} topic={item.topic} statement={item.statement} requirement={item.requirement} />
            })
          }
        </Spin>

        {
          taskData.length === 0 && !loading &&
          <div>
            暂无数据
          </div>
        }
        
      </div>
    </>
  )
}

export default CRM;