import { createBrowserRouter } from 'react-router-dom';
import Root from './Root'
import Login from './Login';
import MyAccount from './MyAccount';
import SelectTask from './SelectTask'
import ViewData from './ViewData';
import ViewUserDetail from './ViewUserDetail';
import ManageUser from './ManageUser';
import CRM from './CRM';
import CreateTask from './CreateTask';
import TaskAssign from './TaskAssign';
import EditTask from './EditTask';
import Writting from './Writting';
import Review from './Review'
import Revision from './Revision'
import Returned from './Returned';
import Previous from './Previous';
import AuthorManage from './AuthorManage';
import ReviewerManage from './ReviewerManage';

const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      errorElement: <h1>404 Not Found</h1>,
      children: [
        {
          path: "/",
          element: <Login />,
        },
        {
          path: "login",
          element: <Login />,
        },
        {
          path: "my-account",
          element: <MyAccount />,
        },
        {
          path: "select-task",
          element: <SelectTask />,
        },
        {
          path: "admin-view-data",
          element: <ViewData />,
        },
        {
          path: "/view-user-detail",
          element: <ViewUserDetail />,
        },
        {
          path: "admin-manage-user",
          element: <ManageUser />,
        },
        {
          path: "admin-crm",
          element: <CRM />,
        },
        {
          path: "create-task/:class",
          element: <CreateTask />,
        },
        {
          path: "task-assign",
          element: <TaskAssign />,
        },
        {
          path: "edit-task/:id",
          element: <EditTask />,
        },
        {
          path: "writting/:id",
          element: <Writting />,
        },
        {
          path: "review/:id",
          element: <Review />,
        },
        {
          path: "revision/:id",
          element: <Revision />,
        },
        {
          path: "returned/:id",
          element: <Returned />,
        },
        {
          path: "previous/:id",
          element: <Previous />,
        },
        {
          path: "manage-author/:id",
          element: <AuthorManage />,
        },
        {
          path: "manage-reviewer/:id",
          element: <ReviewerManage />,
        }
      ],
    },
  ])

  export default router