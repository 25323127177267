import React, { useState, useEffect } from 'react';
import { Table, Radio, Input, Button, Spin, message, Modal } from 'antd';
import styles from '../src/css/Revision.module.scss'
import TabBar from './components/TabBar';
import { useNavigate, useParams } from 'react-router';
import revisionStore from './store/revisionStore';
import { observer } from "mobx-react";
import EInput from './components/EInput';
import { PlusOutlined, CloseCircleOutlined, ArrowRightOutlined } from '@ant-design/icons';
import RevisionCard from './components/RevisionCard';
import { countWords } from './utils/utils';
import fileDownload from 'js-file-download'
import Score from './components/Score';

function Revision() {

 
  const { TextArea } = Input;
  const [saveLoading, setSaveLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)

  const disabled = revisionStore.revision.submitted

  const navigate = useNavigate()
  const params = useParams()
 
  const { confirm } = Modal;
  
  useEffect(() => {
    revisionStore.fetchTask(params.id)
  }, [])

  // const handleDeleteOutline = (index) => {
  //   messageApi.confirm({
  //     title: 'Are you sure to delete this subclaim?',

  //   })
  // }

  const saveRevision = async () => {
    setSaveLoading(true)
    await revisionStore.saveRevision()
    messageApi.open({
      type: 'success',
      content: 'Saved successfully',
    });
    setSaveLoading(false)
  }

  const submitRevision = async () => {
    // 校验
    const words = countWords(revisionStore.revision.content)
    if(!revisionStore.revision.title) {
      messageApi.open({
        type: 'warning',
        content: 'Please input a title for your essay',
      });
      return
    }
    if (words < 300) {
      messageApi.open({
        type: 'warning',
        content: 'You need to write at least 300 words.',
      });
      return
    }

    const unable = revisionStore.reviews.some(item => { return !item.authorScore || !item.authorResponse })

    if(unable) {
      messageApi.open({
        type: 'warning',
        content: 'Respond to reviewer feedback before submitting your revision.',
      });
      return
    }

    // 二次确认
    confirm({
      title: 'Attention',
      icon: null,
      content: 'Are you sure you want to submit your revised essay and responses? Once submitted, they will be sent to the reviewer.',
      bodyStyle: {
        textAlign: 'center'
      },
      onOk() {
        submit()
      }
    })
  }


  const countFormatter = (val) => {
    const cnt = countWords(val.value)
    return <div className={styles.cntWord}>{cnt} words</div>;
  }

  const  submit = async () => {
    setSubmitLoading(true)
    await revisionStore.submitRevision()
    setSubmitLoading(false)
  }


  const download = () => {
    const downloadContent = revisionStore.revision.title + '\n\n' + revisionStore.revision.content
     fileDownload(downloadContent, 'My Essay.txt')
 }

 
  const [messageApi, contextHolder] = message.useMessage();

  return (
    <>
      <TabBar title="Revision" subTitle={`Topic: ${revisionStore.taskProfile.topic || ''}`} />
      <Spin spinning={revisionStore.loading}>
        <div className={styles.body}>
          {contextHolder}
          <div className={styles.left}>
            <div className={styles.seg}>
              <EInput disabled={disabled} width={40} onChange={value => revisionStore.revision.title = value} value={revisionStore.revision.title} label="Title" placeholder="Please input a title for your essay"/>
            </div>

            <div className={styles.seg}>
              <TextArea disabled={disabled} onChange={(e) => revisionStore.revision.content = e.target.value} style={{resize: 'none'}} value={revisionStore.revision.content} className={styles.revisionTextArea} bordered={false} showCount={{ formatter: countFormatter }}/>
            </div>

            {
              disabled ? 
                <div className={styles.btnDownload}>
                    <Button className={styles.btn} size='large' type="primary" onClick={download}>Download My Essay</Button> 
                </div> 
              :
                <div className={styles.btnGroup}>
                  <Button className={styles.btn} loading={saveLoading} size='large' type="primary" onClick={saveRevision}>Save</Button>
                  <Button className={styles.btn} loading={submitLoading} size='large' type="primary" onClick={submitRevision}>Submit</Button>

                  <div className={styles.text}>Respond to reviewer feedback before submitting your revision. </div>
                </div>
            }
            
          </div>

          <div className={styles.right}>
              <div className={styles.titleTop}>Your essay has received a reviewer score of <span className={styles.score}>{revisionStore.taskAuthor.partnerScore}</span></div>
              {
                revisionStore.reviews.map((item, index) => {
                  return <RevisionCard disabled={disabled}  review={item} index={index}/>
                })
              }
          </div>

          
        </div>
      </Spin>
    </>
  )
}

export default observer(Revision);