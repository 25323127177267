import React from 'react'
import styles from '../css/Steps.module.scss'
import Step from '../img/step.png'

export default class Steps extends React.Component {

  constructor(props) {
    super(props)
  }

  changeValue = (value) => {
    const { onChange, maxStep } = this.props
    if (value <= maxStep) {
      onChange && onChange(value)
    }
  }


  render() {
    const { titleArr, step } = this.props

    return (
      <div className={styles.group}>
       {
          titleArr.map((value, index) => {
            const active = step === index + 1
            return (
              <div className={[styles.step, active ? styles.active : ''].join(' ')} onClick={() => {this.changeValue(index + 1)}}>
                { active &&
                  <img className={styles.icon} src={Step}/>
                }
                <span className={styles.title}>{ value }</span>
              </div>
            )
          })
       }
      </div>
    )
  }
  
}
