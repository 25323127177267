import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { Checkbox, Spin, message } from 'antd';

import { userLogin } from './utils/request';

import styles from './css/Login.module.scss';
import logo from './img/logo.png';
import loginAccount from './img/login-account.png';
import loginPsw from './img/login-psw.png';
import loginGuide from './img/login-guide.png'

export default function Login () {

        const navigate = useNavigate()

        const [email, setEmail] = useState('')
        const [password, setPassword] = useState('')
        const [loading, setLoading] = useState(false)

        const login = async () => {
            setLoading(true)
            try {
                const result = await userLogin({ email, password })
                setLoading(false)
                console.log('result', result)
                if (result.code == 0 ) {
                    if(result.data.isAdmin) {
                        navigate('/admin-crm', {replace: true})
                    } else {
                        navigate('/select-task', {replace: true})
                    }
                }
            } catch (e) {
                setLoading(false)
            }
          
        }

        const canLogin = email && password

        const showHint = () => {
            let hint = ''
            if (!email) {
                hint = 'Please enter your email'
            } else if (!password) {
                hint = 'Please enter your password'
            }
            if (hint) {
                message.warning(hint)
            }

        }

        return (
            <div className={styles.body}>
                <div className={styles.logIn}>
                    <div className={styles.left}>
                        <img src={logo} className={styles.leftLogo} alt="logo" />
                        <div className={styles.leftContent}>
                            <p>Welcome to EvaluMate, an innovative online platform that combines AI technology with interactive activities to enhance your argumentative writing skills through engaging writing exercises and peer reviews.</p>

                            <p>At EvaluMate, you have the chance to experience two distinct roles: Author and Reviewer. As an Author, you can sharpen your writing abilities by crafting compelling essays. As a Reviewer, you can refine your feedback skills by providing insightful critiques. Throughout this process, an AI chatbot will serve as your learning companion, guiding you through the essay writing and feedback process.</p>

                            <p>In addition, EvaluMate introduces a gamified learning environment, adding an element of fun and excitement to your learning journey. By producing high-quality essays and offering constructive feedback, you'll earn points that contribute to your position on the leaderboard. Aim high and strive to become the top author or reviewer in your class.</p>

                            <p>Join EvaluMate and become exceptional writers and reviewers!</p>

                            <p><a className={styles.xxLink} target="_blank" href="https://periwinkle-slash-7d2.notion.site/EvaluMate-Demo-Video-f23aa9136f8c41d69980678cceb79821?pvs=4" >Watch Demo Video</a></p>
                            
                        </div>
                    </div>
                    <Spin spinning={loading}>
                        <div className={styles.right}>
                            <img src={loginGuide} className={styles.loginGuide} />
                            <div className={styles.rightBox}>
                                <div className={styles.rightTitle}>Log in to EvaluMate</div>
                                    <div className={styles.account}>
                                        <img src={loginAccount} className={styles.accountImg} />
                                        <input placeholder='Email' className={styles.input} value={email} onChange={(e) => setEmail(e.target.value)}></input>
                                    </div>
                                    <div className={styles.password}>
                                        <img src={loginPsw} className={styles.pswImg} />
                                        <input type="password" placeholder='Password' className={styles.input} value={password} onChange={(e) => setPassword(e.target.value)}></input>
                                    </div>
                                
                                <div className={canLogin ? styles.buttonNormal : styles.buttonDisable} onClick={canLogin ? login : showHint}>
                                    Log in
                                </div>

                                <div className={styles.loginHint}>
                                    By using EvaluMate, you agree to our <a className={styles.underLink} target="_blank" href="https://cosmic-porkpie-7f1.notion.site/EvaluMate-Terms-of-Service-d5472d35aa4a41d7b756a2409d091289?pvs=4">Terms of Service</a> and <a className={styles.underLink}  target="_blank" href="https://cosmic-porkpie-7f1.notion.site/EvaluMate-Privacy-Policy-6dbbbb6c4898499482cddc63afb6fe47?pvs=4">Privacy Policy</a>. 
                                </div>
                            </div>
                        </div>
                    </Spin>
                </div>
                <a className={styles.link} target="_blank" href='https://cosmic-porkpie-7f1.notion.site/EvaluMate-Development-Team-93982260b04c4caa8d9588a84971c27c?pvs=4'>
                    Our development team
                </a>
            </div>
        )
    }
