import { action, makeAutoObservable } from "mobx";
import { getTaskDetail, getSuggestClaim, getSuggestCounterargument, saveOutline, saveDraft, saveSubmission, getEvaluateSubmission} from "../utils/request";
import { create, persist } from 'mobx-persist'


// Todo 總數據源
class TaskStore {

  taskProfile = null
  loading = false
  aiLoading = false
  step = 0
  maxStep = 0

  id = null

  outline = null
  draft = null
  submission = null

  // totalScore = null
  organizationScore = null
  contentScore = null
  languageScore = null

  taskAuthor = {}
  
  constructor() {
    makeAutoObservable(this);
  }

  dealWithOutlineAi = (aiHelps) => {
    const returnHelps = [
      {
        role: 'assistant',
        content: `[Task Instruction] \n \r\n ${this.taskProfile.statement} \r\n \n ${this.taskProfile.requirement}`
      },
      {
        role: 'assistant',
        content: 'Let me know if you need my help.'
      },
      ...aiHelps
    ]
    return returnHelps
  }

  dealWithDraftAi = () => {
    const returnHelps = [
      {
        role: 'assistant',
        content: 'Hi! I\'ve generated a draft based on the outline you provided. Now it\'s time for you to refine and improve the draft further. Be sure to include an introduction and conclusion to your essay, as they are essential components that help to frame your argument and leave a lasting impression on your reader.'
      },
      {
        role: 'assistant',
        content: `[Task Instruction] \n \r\n ${this.taskProfile.statement} \r\n \n ${this.taskProfile.requirement}`
      },
      {
        role: 'assistant',
        content: 'Once you\'ve completed your draft, simply click on the \'Proceed\' button to notify me. I\'ll evaluate your work and provide you with feedback. To help you understand my evaluation process, I\'ve listed my criteria below. \n\n [Evaluation Criteria] \n\n I will evaluate your essay based on the following three aspects: \n \n 1. Content: The extent to which the writer presents a clear and convincing argument, supports it with evidence, and addresses counterarguments. \n\n 2. Organization: The extent to which the writer structures the essay in a coherent and logical manner, and effectively transitions between ideas. \n\n 3. Language: The extent to which the writer uses appropriate, precise, and varied language, and demonstrates control over grammar, syntax, and mechanics.'
      },
    ]
    return returnHelps
  }

  fetchTask = async (id) => {
    this.loading = true
    const res = await getTaskDetail(id)
    const task = res.data

    this.taskProfile = task.taskProfile
    this.outline = task.outline
    this.draft = task.draft
    this.submission = task.submission

    this.taskAuthor = task.taskAuthor

    // this.totalScore = task.totalScore
    this.organizationScore = task.organizationScore
    this.contentScore = task.contentScore
    this.languageScore = task.languageScore

    this.id = task.id
    this.outline.subClaims = task.outline.subClaims || [{
      claim: '',
      evidence: '',
      reasoning: ''
    }]

    this.outline.counterarguments = task.outline.counterarguments || [{
      argument: '',
      rebuttal: ''
    }]

    // outline的AI前置提示
    this.outline.aiHelps = this.dealWithOutlineAi(task.outline.aiHelps)
    this.draft.aiHelps = this.dealWithDraftAi()

    if (!task.outline.submitted) {
      this.step = 1
      this.maxStep = 1
    } else if (!task.draft.submitted) {
      this.step = 2
      this.maxStep = 2
    } else {
      this.step = 3
      this.maxStep = 3
    }


    
    this.loading = false
  }

  setStep = (step) => {
    this.step = step
  }

  addSubclaim = () => {
    this.outline.subClaims.push({
      claim: '',
      evidence: '',
      reasoning: ''
    })
  }

  addCounterAugument = () => {
    this.outline.counterarguments.push({
      argument: '',
      rebuttal: ''
    })
  }

  deleteSubclaim = (index) => {
    this.outline.subClaims.splice(index, 1)
  }

  deleteCounterArgument = (index) => {
    this.outline.counterarguments.splice(index, 1)
  }
  

  suggestSubclaim = async () => {

    if(this.aiLoading || this.outline.aiSubclaimLeft <= 0) return

    this.aiLoading = true
    const res = await getSuggestClaim({
      taskId: this.id,
      mainClaim: this.outline.mainClaim,
      subClaims: this.outline.subClaims.map(item => item.claim).filter(item => item),
    })
    this.aiLoading = false
    
    const task = res.data
    this.outline.aiSubclaimLeft = task.outline.aiSubclaimLeft
    this.outline.aiHelps = this.dealWithOutlineAi(task.outline.aiHelps)
    return res
  }

  suggestCounterargument = async () => {

    if(this.aiLoading || this.outline.aiCounterargumentLeft <= 0) return

    this.aiLoading = true
    const res = await getSuggestCounterargument({
      taskId: this.id,
      mainClaim: this.outline.mainClaim,
      subClaims: this.outline.subClaims.map(item => item.claim).filter(item => item),
      counterarguments: this.outline.counterarguments.map(item => item.argument).filter(item => item),
    })
    this.aiLoading = false
    
    const task = res.data
    this.outline.aiCounterargumentLeft = task.outline.aiCounterargumentLeft
    this.outline.aiHelps = this.dealWithOutlineAi(task.outline.aiHelps)
    return res
  }

  saveTaskOutline = async () => {
    const result = await saveOutline({
      taskId: this.id,
      mainClaim: this.outline.mainClaim,
      subClaims: this.outline.subClaims,
      counterarguments: this.outline.counterarguments
    })
    return result
  }

  submitTaskOutline = async () => {
    const result = await saveOutline({
      taskId: this.id,
      mainClaim: this.outline.mainClaim,
      subClaims: this.outline.subClaims,
      counterarguments: this.outline.counterarguments,
      submitted: true
    })
    this.outline = result.data.outline

    this.draft = result.data.draft
    this.draft.aiHelps = this.dealWithDraftAi()
    this.step = 2
    this.maxStep = 2
    
    return result
  }

  saveTaskDraft = async () => {
    const result = await saveDraft({
      taskId: this.id,
      title: this.draft.title,
      content: this.draft.content,
    })
    return result
  }

  submitTaskDraft = async () => {
    const result = await saveDraft({
      taskId: this.id,
      title: this.draft.title,
      content: this.draft.content,
      submitted: true
    })

    this.draft = result.data.draft
    this.draft.aiHelps = this.dealWithDraftAi()
    
    this.submission = result.data.submission

    this.step = 3
    this.maxStep = 3
    
    return result
  }

  saveTaskSubmission = async () => {
    const result = await saveSubmission({
      taskId: this.id,
      title: this.submission.title,
      content: this.submission.content,
    })
    return result
  }

  submitTaskSubmission = async () => {
    const result = await saveSubmission({
      taskId: this.id,
      title: this.submission.title,
      content: this.submission.content,
      submitted: true
    })

    const task = result.data
    
    this.submission = result.data.submission
    this.taskAuthor = result.data.taskAuthor

    // this.totalScore = task.totalScore
    this.organizationScore = task.organizationScore
    this.contentScore = task.contentScore
    this.languageScore = task.languageScore

    return result
  }

  evaluateSubmission = async () => {
    if(this.aiLoading || this.submission.aiEvaluateLeft <= 0) return

    this.aiLoading = true

    try {
      const res = await getEvaluateSubmission({
        taskId: this.id,
        title: this.submission.title,
        content: this.submission.content,
      })
      this.aiLoading = false
      const task = res.data
      this.submission = task.submission
      return res
    } catch(e) {
      this.aiLoading = false
    }
    
  }
}


const schema = {
  
  topic: true,
  statement: true,
  requirement: true,
  
}


const hydrate = create({
  storage: localStorage,   // or AsyncStorage in react-native.
  jsonify: true  // if you use AsyncStorage, here shoud be true
})

const taskStore = persist(schema)(new TaskStore())

hydrate('taskStore', taskStore).then(() => console.log('taskStore has been hydrated'))

export default taskStore;
