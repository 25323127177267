import React from 'react';
import { Radio, Space, Button } from 'antd';
import styles from '../css/TaskProfileItem.module.scss';
import EditIcon from '../img/edit-icon.png';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

export default class TaskProfileItem extends React.Component {
  constructor(props) {
    super(props)
  }

  handleAuthor = () => {
    const { taskProfileId } = this.props
    window.open(`/manage-author/${taskProfileId}`, '_blank')
  }

  handleReviewer = () => {
    const { taskProfileId } = this.props
    window.open(`/manage-reviewer/${taskProfileId}`, '_blank')
  }

  render() {
    const {taskProfileId, topic, requirement, statement} = this.props
    
      return(<div className={styles.item}>
        <div className={styles.titleWrapper}>
          <div className={styles.title}>Topic: {topic}</div>
          <Link to={`/edit-task/${taskProfileId}`}>
           <img src={EditIcon} className={styles.editIcon}></img>
          </Link>
        </div>

        <div className={styles.contentWrapper}>
          <div className={styles.content}>
            <div>Task Instruction:</div>
            <div>{statement}</div>
            <div>{requirement}</div>
          </div>

          <div className={styles.buttonGroup}>
            <Button onClick={this.handleAuthor} size="small" ghost type="primary" className={styles.button}>Author Task Management</Button>
            <Button onClick={this.handleReviewer} size="small" ghost type="primary" className={styles.button}>Reviewer Task Management</Button>
          </div>
        </div>
      </div>)
  }
  
}
