import React from 'react';
import { Radio, Space, Button } from 'antd';
import styles from '../css/ReviewerTaskItem.module.scss';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import userStore from '../store/userStore';


export default class ReviewerTaskItem extends React.Component {
  constructor(props) {
    super(props)
  }

  handleJump(subTask) {
    const {step, reviews} = subTask
    if (step !== 5 ) {
      window.open(`/review/${subTask.id}`, '_blank')
    } else if (step === 5) {
      window.open(`/returned/${subTask.id}`, '_blank')
    }
  }

  render() {
    const {task} = this.props
    const getTaskLabel = (task) => {
      const { step } = task
      const submitted = task.reviews[0].submitted

      if (submitted && step === 2) {
        return 'Review Completed'
      }

      switch(step) {
        case 2:
          return 'Awaiting Review'
        case 3:
          return 'Review Completed'
        case 4:
          return 'Review Completed'
        case 5:
          return 'Revision Returned'
        default:
          return ''
      }
    }

    const taskUser = task.tasks[0].reviews[0].taskReviewer

    const availableTask = task.tasks.filter(item => item.step > 1)

   

    
      return(
        <div className={styles.item}>
          <div className={styles.titleWrapper}>
            <div className={styles.title}>Topic: {task.topic}</div>

            <div className={styles.score}>
              {
                !userStore.profile.aiDisabled && <div className={styles.aiScore}>AI Score: {taskUser.aiScore || '—'}</div>
              }
              {
                !userStore.profile.aiDisabled && <div className={styles.aiScore}>Author Score: {taskUser.partnerScore || '—'}</div>
              }
              {
                !userStore.profile.aiDisabled && <div className={styles.ranking}>Ranking: {taskUser.rank ? `${taskUser.rank}/${task.totalReviewers}` : '—'}</div>
              }
            </div>
          </div>

          <div className={styles.contentWrapper}>

            {
              availableTask.length === 0 && <div className={styles.hint}>
                Awaiting author submission
              </div>
            }
            {
              availableTask.length > 0 && availableTask.map((subTask) => {

                const label = getTaskLabel(subTask)

                const isCompleted = label === 'Review Completed'

                // if()
                return (
                  <div className={styles.subTask}>
                    <div className={styles.title}>{subTask.submission.title}</div>
                    <div className={styles.btn}>
                        <Button className={ isCompleted ? styles.green : null } type={'primary'} onClick={() => this.handleJump(subTask)} ghost={isCompleted || subTask.step===5} >
                        {
                          label
                        } 
                        </Button>
                    </div> 
                  </div>
                )
              })
            }

          </div>
        </div>

      )
  }
  
}
