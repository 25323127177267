import React, { useState, useEffect } from 'react';
import { Table, Radio, Input, Button, Spin, message, Modal } from 'antd';
import styles from './css/Returned.module.scss'
import TabBar from './components/TabBar';
import { useNavigate, useParams } from 'react-router';
import revisionStore from './store/revisionStore';
import { observer } from "mobx-react";
import { countWords } from './utils/utils';
import ReturnBg from './img/return-bg.png'
import userStore from './store/userStore';

function Previous() {

  const navigate = useNavigate()
  const params = useParams()
 
  const { confirm } = Modal;
  
  useEffect(() => {
    revisionStore.fetchTask(params.id)
  }, [])



  const countFormatter = (val) => {
    const cnt = countWords(val.value)
    return <div className={styles.cntWord}>{cnt} words</div>;
  }
  
  return (
    <>
      <TabBar title="Revision Returned"/>
      <Spin spinning={revisionStore.loading}>
        <div className={styles.body}>
          <img src={ReturnBg} className={styles.bg} />
          <div className={styles.left}>
            <div className={styles.segTitle}>
              Previous Version
            </div>
            <div className={styles.seg}>
              <div className={styles.contentTitle}>
                {
                  revisionStore.submission.title 
                }
              </div>
              <div className={styles.content}>
                {
                  revisionStore.submission.content
                }
              </div>
            </div>
             
          </div>

          <div className={styles.right}>
             
          </div>
        </div>
      </Spin>
    </>
  )
}

export default observer(Previous);