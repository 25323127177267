import React from 'react';
import { Radio, Space, Button } from 'antd';
import styles from '../css/AuthorTaskItem.module.scss';
import EditIcon from '../img/edit-icon.png';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import DotsIcon from '../img/dots.png';
import ArrowIcon from '../img/arrow.png';


export default class AuthorTaskItem extends React.Component {
  constructor(props) {
    super(props)
  }

  handleRevision = () => {
    const {task} = this.props
    if (task.step < 3) {
      return 
    }
    window.open(`/revision/${task.id}`, '_blank')
  }

  handleWritting = () => {
    const {task} = this.props
    window.open(`/writting/${task.id}`, '_blank')
  }

  render() {
    const {task} = this.props
    const getTaskLabel = (step) => {
      switch(step) {
        case 3:
          return 'Start'
        case 4:
          return 'Continue'
        case 5:
          return 'View'
        default:
          return ''
      }
    }
    
      return(
        <div className={styles.item}>
          <div className={styles.titleWrapper}>
            <div className={styles.title}>Topic: {task.taskProfile.topic}</div>

            <div className={styles.score}>
              <div className={styles.aiScore}>AI Score: {task.taskAuthor.aiScore || '—'}</div>
              <div className={styles.aiScore}>Reviewer Score: {task.taskAuthor.partnerScore || '—'}</div>

              <div className={styles.ranking}>Ranking: {task.taskAuthor.rank ? `${task.taskAuthor.rank}/${task.taskProfile.totalAuthors}` : '—'}</div>
            </div>
          </div>

          <div className={styles.contentWrapper}>

            <div className={styles.round} onClick={this.handleWritting}>
                <div className={styles.title}>Writting</div>
                <div className={styles.btn}>{
                  task.step === 0 ? 'Start' : task.step === 1 ? 'Continue' : 'View'
                } <img src={ArrowIcon} className={styles.arrow}/> </div>
            </div>

            <img className={styles.dots} src={DotsIcon} />
            <div className={styles.waiting}>Awaiting peer review</div>
            <img className={styles.dots} src={DotsIcon} />

            <div className={[styles.round, task.step < 3 ? styles.disabled : ''].join(' ')} onClick={this.handleRevision}>
              <div className={styles.title}>Revision</div>
              {task.step > 2 &&
                <div className={styles.btn}>{
                  getTaskLabel(task.step)
                } <img src={ArrowIcon} className={styles.arrow}/> </div> 
              }
            </div>

          </div>
        </div>

      )
  }
  
}
