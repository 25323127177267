import React, { useState, useEffect } from 'react';
import { Table, Radio, Input, Button, Spin, message, Modal } from 'antd';
import styles from '../src/css/ReviewerManage.module.scss'
import TabBar from './components/TabBar';
import { useNavigate, useParams } from 'react-router';
import { observer } from "mobx-react";
import EInput from './components/EInput';
import { PlusOutlined, CloseCircleOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { countWords } from './utils/utils';
import fileDownload from 'js-file-download'
import Score from './components/Score';
import { getTaskParticipants } from './utils/request';
import AiHelp from './components/AiHelp';
import CommentCard from './components/CommentCard';

function ReviewerManage() {

 
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const [selectedRow, setSelectedRow] = useState()
  const [selectedReviewerName, setSelectedReviewerName] = useState()

  const navigate = useNavigate()
  const params = useParams()
 
  const { confirm } = Modal;
  
  useEffect(() => {
    fetchTask(params.id)
  }, [])

  useEffect(() => {
    console.log(`count has been updated: ${selectedRow && selectedRow.authorName}`);
  }, [selectedRow]);

  const fetchTask = async (id) => {
    setLoading(true)
    const result = await getTaskParticipants({
      taskProfileId: id,
      role: 'reviewer'
    })
    console.log(result.data)

    setData(result.data)

    setLoading(false)

  }

  const selectReview = (review, name) => {
    setSelectedRow(review)
    setSelectedReviewerName(name)
  }

  const renderReviewBtn = (review, name) => {

    const selected = selectedRow && selectedRow.id === review.id 
    if(review.task.step === 0 || review.task.step === 1) {
      return <Button  className={styles.btn} disabled> {review.authorName}</Button>
    } else if(review.task.step === 2) {
      return <Button type={selected?'primary': 'default'} className={styles.btn} onClick={() => selectReview(review, name)}>{review.authorName}</Button>
    } else if(review.task.step === 3 || review.task.step === 4) {
      return <Button type={selected?'primary': 'default'} onClick={() => selectReview(review, name)} className={[styles.btn, selected ? '':styles.green].join(' ')}>{review.authorName}</Button>
    } else {
      return <Button type={selected?'primary': 'default'} onClick={() => selectReview(review, name)} className={[styles.btn, selected ? '':styles.orange].join(' ')}>{review.authorName}</Button>
    }
  }
 

  const countFormatter = (val) => {
    const cnt = countWords(val)
    return <div className={styles.cntWord}>{cnt} words</div>;
  }
 
  const [messageApi, contextHolder] = message.useMessage();

  const profile = data[0] && data[0].taskProfile

  return (
    <>
      <TabBar title="Reviewer Task Management" />
      <Spin spinning={loading}>
        <div className={styles.body}>
          {contextHolder}
          
          {
            profile && 
            <div className={styles.left}>

              <div className={styles.segTitle}>
                Topic: {profile.topic}
              </div>

              <div className={styles.seg}>
                <div className={styles.head}>
                  <div className={styles.first}>Reviewer Name</div>
                  <div>Author Name</div>
                </div>
                {
                  data.map((item, index) => {
                    return (<div className={styles.tableRow}>
                      <div className={styles.first}>{item.name}</div>
                      <div className={styles.btns}>
                        {
                          item.reviews.map(review => {
                            return renderReviewBtn(review, item.name)
                          })
                        }
                      </div>
                    </div>)
                  })
                }
              </div>
              
            </div>
          }
          

          <div className={styles.right}>
            {
              selectedRow &&
              <div className={styles.titleTop}>
                <div className={styles.titleLeft}>
                  <div>
                    Author: {selectedRow.authorName}
                  </div>
                </div>
              </div>
            }
            {
              selectedRow && 
              <div>
                 <div className={[styles.seg, styles.rightSeg].join(' ')}>
                    <div className={styles.contentTitle}>
                      {
                        selectedRow.task.submission.title 
                      }
                    </div>
                    <div className={styles.content}>
                      {
                        selectedRow.task.submission.content
                      }
                    </div>
                    <div>{countFormatter(selectedRow.task.submission.content)}</div>
                </div>
                <div style={{marginTop: '40px'}}>
                  {
                    selectedRow.task.step === 2 && !selectedRow.submitted &&
                    <div className={styles.finalHint}>Awaiting peer review submission </div>
                  }
                  <CommentCard showAiScore review={selectedRow} title={`${selectedReviewerName}`}/>
                  {
                    (selectedRow.task.step === 3 || selectedRow.task.step === 4) &&
                    <div className={styles.finalHint}>Awaiting author response </div>
                  }

                </div>

                <div style={{marginTop: '40px'}}>
                  <div className={styles.ai}>
                    <AiHelp aiHelps={selectedRow.aiHelps} showPrompt/>
                  </div>
                </div>

              </div>
            }

          {/* {
              selectedRow && type=='revision' &&
              <div>
                 <div className={[styles.seg, styles.rightSeg].join(' ')}>
                    <div className={styles.contentTitle}>
                      {
                        selectedRow.revision.title 
                      }
                    </div>
                    <div className={styles.content}>
                      {
                        selectedRow.revision.content
                      }
                    </div>
                    <div>{countFormatter(selectedRow.revision.content)}</div>
                </div>

                <div style={{marginTop: '40px'}}>
                      {
                        selectedRow.reviews.map((item, index) => {

                          return (
                            <CommentCard review={item} title={`${item.taskReviewer.user.name}`}/>
                          )
                        })
                      }
                  
                </div>
              </div>
            } */}

          </div>
        </div>
      </Spin>
    </>
  )
}

export default ReviewerManage;