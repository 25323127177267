import React, { useState, useEffect } from 'react';
import { Table, Radio, Input, Button, Spin, message, Modal } from 'antd';
import styles from '../src/css/Writting.module.scss'
import TabBar from './components/TabBar';
import { useNavigate, useParams } from 'react-router';
import taskStore from './store/taskStore';
import { observer } from "mobx-react";
import EInput from './components/EInput';
import { PlusOutlined, CloseCircleOutlined, ArrowRightOutlined } from '@ant-design/icons';
import AiHelp from './components/AiHelp';

function Outline() {

 
  const { confirm } = Modal;
  const [saveLoading, setSaveLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  
  useEffect(() => {

  }, [])

  // const handleDeleteOutline = (index) => {
  //   messageApi.confirm({
  //     title: 'Are you sure to delete this subclaim?',

  //   })
  // }

  const suggestSubclaim = () => {
    if(taskStore.aiLoading || taskStore.outline.aiSubclaimLeft <= 0) {
      messageApi.open({
        type: 'warning',
        content: 'You have run out of chances.',
      });
      return
    }
    if (!taskStore.outline.mainClaim) {
      messageApi.open({
        type: 'warning',
        content: 'Please input your main claim first',
      });
      return
    }
    taskStore.suggestSubclaim()
  }

  const suggestCounterargument = () => {
    if(taskStore.aiLoading || taskStore.outline.aiCounterargumentLeft <= 0) {
      messageApi.open({
        type: 'warning',
        content: 'You have run out of chances.',
      });
      return
    }
    if (!taskStore.outline.mainClaim) {
      messageApi.open({
        type: 'warning',
        content: 'Please input your main claim first',
      });
      return
    }
    taskStore.suggestCounterargument()
  }



  const saveOutline = async () => {
    setSaveLoading(true)
    await taskStore.saveTaskOutline()
    messageApi.open({
      type: 'success',
      content: 'Saved successfully',
    });
    setSaveLoading(false)
  }

  const submitOutline = async () => {
    // 校验
    const subclaim = !taskStore.outline.subClaims[0].claim || !taskStore.outline.subClaims[0].evidence || !taskStore.outline.subClaims[0].reasoning
    const counterargument = !taskStore.outline.counterarguments[0].argument || !taskStore.outline.counterarguments[0].rebuttal
    if (!taskStore.outline.mainClaim || subclaim || counterargument) {
      messageApi.open({
        type: 'warning',
        content: 'Please complete Main claim, Subclaim 1 (and its Evidence and Reasoning), and Counterargument 1 (and its Rebuttal).',
      });
      return
    }

    // 二次确认
    confirm({
      title: 'Attention',
      icon: null,
      content: 'You won’t be able to make changes to this page if you proceed to the next step. Are you sure you want to proceed?',
      bodyStyle: {
        textAlign: 'center'
      },
      onOk() {
        submit()
      }
    })
  }

  const  submit = async () => {
    setSubmitLoading(true)
    await taskStore.submitTaskOutline()
    setSubmitLoading(false)
  }
      
 
  const [messageApi, contextHolder] = message.useMessage();
  const disabled = taskStore.outline.submitted

  return (
    <div className={styles.body}>
      {contextHolder}
      <div className={styles.content}>
        <div className={styles.seg}>
          <EInput disabled={disabled} width={80} onChange={value => taskStore.outline.mainClaim = value} value={taskStore.outline.mainClaim} label="Main claim" placeholder="Your main argument or thesis statement"/>
        </div>


        {
          taskStore.outline.subClaims.map((item, index) => {
            return (
            <>
            {
              index !== 0 && !taskStore.outline.submitted &&
              <CloseCircleOutlined className={styles.close} onClick={() => taskStore.deleteSubclaim(index)}/>
            }

              <div className={styles.seg}>
                <EInput disabled={disabled} width={80} onChange={(value) => item.claim = value} value={item.claim} label={`Subclaim ${index + 1}`} placeholder="A smaller claim that support your main claim"/>
                <EInput disabled={disabled} width={80} onChange={(value) => item.evidence = value} value={item.evidence} label="Evidence" placeholder="Supporting information that backs up your subclaim"/>
                <EInput disabled={disabled} width={80} onChange={(value) => item.reasoning = value} value={item.reasoning} label="Reasoning" placeholder="Explanation on how the evidence supports your subclaim"/>
              </div>

            </>
          )
          })
        }

        <div className={styles.btnWrapper}>
          <Button disabled={disabled} onClick={taskStore.addSubclaim} icon={<PlusOutlined />} className={styles.btn} type="primary" ghost >Add one more subclaim</Button>
        </div>

       {
          taskStore.outline.counterarguments.map((item, index) => {
            return (
            <>
            {
              index !== 0 && !taskStore.outline.submitted &&
              <CloseCircleOutlined className={styles.close} onClick={() => taskStore.deleteCounterArgument(index)}/>
            }
              <div className={styles.seg}>
                <EInput  disabled={disabled} width={120} onChange={(value) => item.argument = value} value={item.argument} label={`Counterargument ${index + 1}`} placeholder="An opposing view that challenges your claim"/>
                <EInput  disabled={disabled} width={120} onChange={(value) => item.rebuttal = value} value={item.rebuttal} label="Rebuttal" placeholder="Your response to the counterargument, involving addressing the counterargument and providing evidence and reansoning"/>
              </div>
            </>
            )
          })
       }

        <div className={styles.btnWrapper}>
          <Button disabled={taskStore.outline.submitted} onClick={taskStore.addCounterAugument} icon={<PlusOutlined />} className={styles.btn} type="primary" ghost >Add one more counterargument</Button>
        </div> 

      </div>
     
      <Spin spinning={taskStore.aiLoading}>
        <div className={styles.rightContent}>
          <div className={styles.ai}>
              <AiHelp showPrompt={window.location.href.indexOf('showPrompt') > 0} full={taskStore.outline.submitted} aiHelps={taskStore.outline.aiHelps} showBtns={!taskStore.outline.submitted} lBtn={{title: 'Suggest a subclaim', subTitle: `(${taskStore.outline.aiSubclaimLeft} times left)`, onClick: suggestSubclaim}} rBtn={{title: 'Suggest a counterargument', subTitle: `(${taskStore.outline.aiCounterargumentLeft} times left)`, onClick: suggestCounterargument}} />
          </div>

          {
            !taskStore.outline.submitted &&
            <div className={styles.btns}>  
              <Button className={styles.btn} loading={saveLoading} size='large' type="primary" onClick={saveOutline}>Save</Button>
              <Button className={styles.btn} loading={submitLoading} size='large' type="primary" onClick={submitOutline}>Proceed <div className={styles.iconDiv}><ArrowRightOutlined className={styles.icon} /></div></Button>
            </div>
          }
        </div>
      </Spin>

     
    </div>
  )
}

export default observer(Outline);