import React, { useState, useEffect } from 'react';
import { Table, Form } from 'antd';
import { getUserDetail } from './utils/request';

import TabBar from './components/TabBar';
import styles from '../src/css/ViewUserDetail.module.scss'
import { useLocation } from 'react-router';
import Loading from './components/Loading';

function ViewUserDetail(props) {

  const [userData, setUserData] = useState()
  const [loading, setLoading] = useState(false)

  // const state = useLocation().state
  const id = window.location.search.substring(1).split('=')[1]

  const getDeatal = async() => {
    setLoading(true)
    const userDetail = await getUserDetail({id: id})
    if (userDetail) {
      setUserData(userDetail.data)
    }
    setLoading(false)
  }

  useEffect(() => {
    getDeatal()
  }, [])

  const ideaColumns = [
    {
      title: ' Issue',
      dataIndex: 'issueType',
      width: 100,
      filters: [
        {
          text: 'Issue A',
          value: 'Issue A',
        },
        {
          text: 'Issue B',
          value: 'Issue B',
        },
        {
          text: 'Issue C',
          value: 'Issue C',
        },
      ],
      onFilter: (value, record) => record.issueType.indexOf(value) === 0,
    },
    {
      title: 'Idea Title',
      dataIndex: 'title',
      width: 200,
    },
    {
      title: 'Idea Content',
      dataIndex: 'content',
    },
  ];

  const commentsColumns = [
    {
      title: ' Issue',
      dataIndex: 'issueType',
      width: 100,
      filters: [
        {
          text: 'Issue A',
          value: 'Issue A',
        },
        {
          text: 'Issue B',
          value: 'Issue B',
        },
        {
          text: 'Issue C',
          value: 'Issue C',
        },
      ],
      onFilter: (value, record) => record.issueType.indexOf(value) === 0,
    },
    {
      title: 'Comment',
      dataIndex: 'content',
    },
  ];

  const evaluationsColumns = [
    {
      title: ' Issue',
      dataIndex: 'issueType',
      width: 100,
      filters: [
        {
          text: 'Issue A',
          value: 'Issue A',
        },
        {
          text: 'Issue B',
          value: 'Issue B',
        },
        {
          text: 'Issue C',
          value: 'Issue C',
        },
      ],
      onFilter: (value, record) => record.issueType.indexOf(value) === 0,
    },
    {
      title: 'Proposal Written By',
      dataIndex: ['proposal', 'creator', 'username'],
      width: 200,
      render: (text, record) => {
        return <div>
            <div>{text}</div>
            <div>({`${record.proposal.country.countryProfile.name}`})</div>
          </div>
      },
    },
    {
      title: 'Score',
      width: 200,
      render: (text, record) => {
        return <div>
            <div>Content: {record.contentScore}; Organization: {record.organizationScore}; Language: {record.languageScore}</div>
          </div>
      }
    },
    {
      title: 'Comment',
      dataIndex: 'content',
      width: 600,
    },
  ];

  if (!userData) {
    return <Loading />
  }
  

  return (
    <div>
      {/* <TabBar /> */}
      <div className={styles.page}>
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
        >
          <Form.Item>
            User name: {userData.username}
          </Form.Item>
          <Form.Item>
            Gender: {userData.gender === 'M' ? 'Male' : 'Femail'}
          </Form.Item>
          <Form.Item>
            Class: {userData.class ? userData.class.name : 'None'}
          </Form.Item>
          <Form.Item>
            Email: {userData.email}
          </Form.Item>
        </Form>
        
        <div className={styles.ideas}>
          <div className={styles.title}>Ideas:</div>
          <Table
            className={styles.table}
            columns={ideaColumns}
            dataSource={userData.ideas}
            sticky
            bordered
            loading={loading}
          />
        </div>

        <div className={styles.comments}>
          <div className={styles.title}>Comments:</div>
          <Table
            className={styles.table}
            columns={commentsColumns}
            dataSource={userData.comments}
            sticky
            bordered
            loading={loading}
          />
        </div>

        <div className={styles.evaluations}>
          <div className={styles.title}>Evaluations:</div>
          <Table
            className={styles.table}
            columns={evaluationsColumns}
            dataSource={userData.evaluations}
            sticky
            bordered
            loading={loading}
          />
        </div>

      </div>
    </div>
  )

  
}


export default ViewUserDetail;