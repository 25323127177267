import { action, makeAutoObservable } from "mobx";
import { getTaskReviewDetail, evaluateReviewComment, submitTaskReview} from "../utils/request";
import { create, persist } from 'mobx-persist'
import userStore from "./userStore";

// Todo 總數據源
class ReviewStore {

  taskProfile = {}
  loading = false
  aiLoading = false
  submitLoading = false

  review = {
    aiHelps: [],
    organizationScore: 0,
    contentScore: 0,
    languageScore: 0,
    comment: '',
  }

  id = null

  // comment = ''

  totalScore = null


  submission ={}
  
  constructor() {
    makeAutoObservable(this);
  }

  dealWithReviewAi = (aiHelps) => {
    
    const returnHelps = userStore.profile.aiDisabled ? [
      {
        role: 'assistant',
        content: `Below are five tips for you to write good comments: \n \r\n 1. A good comment includes encouraging and positive emotions such as praise or compliments.\n 2. A good comment includes a summary statement such as description of content or taken action to a large extent. \n 3. A good comment includes explicit and localized identification of problem. \n 4. A good comment includes elaborations and justifications of identified problem. \n 5. A good comment includes recommendations and action plans for further improvements. \n`
      },
      ...aiHelps
    ] : [
      {
        role: 'assistant',
        content: `Below are five tips for you to write good comments: \n \r\n 1. A good comment includes encouraging and positive emotions such as praise or compliments.\n 2. A good comment includes a summary statement such as description of content or taken action to a large extent. \n 3. A good comment includes explicit and localized identification of problem. \n 4. A good comment includes elaborations and justifications of identified problem. \n 5. A good comment includes recommendations and action plans for further improvements. \n`
      },
      {
        role: 'assistant',
        content: 'Let me know if you want me to help check your comments.'
      },
      ...aiHelps
    ]

    if (this.review.aiDisabled) {
      returnHelps.splice(1,1)
    }
    return returnHelps
  }


  fetchTask = async (id) => {
    this.loading = true
    const res = await getTaskReviewDetail(id)
    const task = res.data

    this.taskProfile = task.taskProfile
    this.submission = task.submission

    this.review = task.reviews[0]
    this.review.aiHelps = this.dealWithReviewAi(task.reviews[0].aiHelps)
    this.review.comment = task.reviews[0].comment || ''

    this.review.organizationScore = task.reviews[0].organizationScore || 0
    this.review.contentScore = task.reviews[0].contentScore || 0
    this.review.languageScore = task.reviews[0].languageScore || 0

    // this.totalScore = task.totalScore
    // this.organizationScore = task.organizationScore
    // this.contentScore = task.contentScore
    // this.languageScore = task.languageScore

    this.id = task.id
    this.loading = false
  }
 
  submitComment = async () => {
    try {
      this.submitLoading = true
      const result = await submitTaskReview({
        reviewId: this.review.id,
        comment: this.review.comment,
        organizationScore: this.review.organizationScore,
        contentScore: this.review.contentScore,
        languageScore: this.review.languageScore,
      })
      const review = result.data
      
      this.review = review
      this.review.aiHelps = this.dealWithReviewAi(review.aiHelps)
      this.submitLoading = false
      return result
    } catch(e) {
      this.submitLoading = false
    }

  }

  evaluateComment = async () => {
    if(this.aiLoading || this.review.aiEvaluateLeft <= 0) return

    this.aiLoading = true

    const res = await evaluateReviewComment({
      reviewId: this.review.id,
      comment: this.review.comment
    })
    this.aiLoading = false
    
    this.review.aiEvaluateLeft = res.data.aiEvaluateLeft
    this.review.aiHelps = this.dealWithReviewAi(res.data.aiHelps)
    return res
  }
}


const schema = {
  
  topic: true,
  statement: true,
  requirement: true,
  
}


const hydrate = create({
  storage: localStorage,   // or AsyncStorage in react-native.
  jsonify: true  // if you use AsyncStorage, here shoud be true
})

const reviewStore = persist(schema)(new ReviewStore())

hydrate('reviewStore', reviewStore).then(() => console.log('reviewStore has been hydrated'))

export default reviewStore;
