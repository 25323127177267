import React, { useState, useEffect } from 'react';
import { Select, Table, Spin } from 'antd';
import { getClass, getTaskProfiles, getTaskParticipants } from './utils/request';
import styles from './css/ViewData.module.scss'

import TabBar from './components/TabBar';

function ViewData(props) {

  const [classInfo, setClassInfo] = useState()
  const [classId, setClassId] = useState()
  const [role, setRole] = useState()
  const [taskId, setTaskId] = useState()
  const [taskData, setTaskData ] = useState([])

  const [authorData, setAuthorData] = useState([])
  const [reviewerData, setReviewerData] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getClassInfo()
  }, [])

  const getClassInfo = async () => {
    const classInfo = await getClass()
    setClassInfo(classInfo.data)
  }

  const classOptions = []
  classInfo && classInfo.map((item) => {
    classOptions.push({
      value: item.name,
      label: item.name
    })
  })

  useEffect(() => {
    if (classId) {
      
      setTaskData([])
      setTaskId()

      setAuthorData([])
      setReviewerData([])

      changeTaskProfiles(classId)
    }
  }, [classId])

  const changeTaskProfiles = async (classId) => {
    const data = await getTaskProfiles(classId)
    setTaskData(data.data)
  }

  const taskOptions = taskData.map(item => {
    return {
      label: item.topic,
      value: item.id
    }
  })

  useEffect(() => {
    if (classId && taskId && role) {
      // 请求table数据
      getTaskParticipantsData({
        taskProfileId: taskId,
        role
      })
    }
  }, [taskId, role, classId])

  const getTaskParticipantsData = async (data) => {
    setLoading(true)

    // setTaskData([])
    // setTaskId()
    setAuthorData([])
    setReviewerData([])

    const res = await getTaskParticipants(data)
    if (res) {
      if (role === 'author') {
        setAuthorData(res.data)
      } else {
        setReviewerData(res.data)
      }
    }
    setLoading(false)
  }

  const authorColumns=  [
    {
      title: 'No.',
      render: (_, record, index) => {
        return <div> {index + 1} </div>
      },
    },
    {
      title: 'Name',
      dataIndex: ['taskAuthor', 'name'],
    },
    {
      title: 'Gender',
      dataIndex: ['taskAuthor', 'user', 'gender'],
      render: (text) => {
        return text === 'F' ? 'Female' : 'Male'
      }
    },
    {
      title: 'Writting',
      dataIndex: ['submission', 'submitted'],
      render: (submitted) => {
        return submitted ? 'Submitted' : '-'
      }
    },
    {
      title: 'AI Score',
      dataIndex: ['taskAuthor', 'aiScore'],
      render: (score) => {
        return score || '-'
      }
    },
    {
      title: 'Reviewer Score',
      dataIndex: ['taskAuthor', 'partnerScore'],
      render: (score) => {
        return score || '-'
      }
    },
    {
      title: 'Revision',
      dataIndex: ['revision', 'submitted'],
      render: (submitted) => {
        return submitted ? 'Submitted' : '-'
      }
    },
    {
      title: 'Total Score',
      dataIndex: ['taskAuthor'],
      render: (author) => {
        return author.aiScore || author.partnerScore ? Number(author.aiScore + author.partnerScore).toFixed(1) :  '-'
      }
    },
    {
      title: 'Ranking',
      dataIndex: ['taskAuthor', 'rank'],
      render: (rank, record) => {
        return rank ? `${rank} / ${record.taskProfile.totalAuthors}` :  '-'
      }
    },
    {
      title: 'Action',
      render: (_, record) => {
        return <a href={`/manage-author/${record.taskProfile.id}`} target='_blank'>Details</a>
      }
    },
  ]
  const reviewerColumns=  [
    {
      title: 'No.',
      render: (_, record, index) => {
        return <div> {index + 1} </div>
      },
    },
    {
      title: 'Name',
      dataIndex: ['user', 'name'],
    },
    {
      title: 'Gender',
      dataIndex: ['user', 'gender'],
      render: (text) => {
        return text === 'F' ? 'Female' : 'Male'
      }
    },
    {
      title: 'Review Status',
      dataIndex: 'reviews',
      render: (reviews) => {
        const reviewNum = reviews.filter(item => item.submitted).length
        return `${reviewNum} / ${reviews.length}`
      }
    },
    {
      title: 'AI Score',
      dataIndex: 'aiScore',
      render: (score) => {
        return score || '-'
      }
    },
    {
      title: 'Author Score',
      dataIndex: 'partnerScore',
      render: (score) => {
        return score || '-'
      }
    },
    {
      title: 'Total Score',
      dataIndex: 'aiScore',
      render: (author, record) => {
        return record.aiScore || record.partnerScore ? Number(record.aiScore + record.partnerScore).toFixed(1) :  '-'
      }
    },
    {
      title: 'Ranking',
      dataIndex: 'rank',
      render: (rank, record) => {
        return rank ? `${rank} / ${record.taskProfile.totalReviewers}` :  '-'
      }
    },
    {
      title: 'Action',
      render: (_, record) => {
        return <a href={`/manage-reviewer/${record.taskProfile.id}`} target='_blank'>Details</a>
      }
    },
  ]

  return (
    <div>
      <TabBar />


      <div className={styles.top}>
        <div className={styles.filter}>
            <div className={styles.selectTitle}>Class</div>
            <Select
              options={classOptions}
              className={styles.selector}
              onChange={(value) => setClassId(value)}
              value={classId}
            />
        </div>

        <div className={styles.filter}>
            <div className={styles.selectTitle}>Task</div>
            <Select
              options={taskOptions}
              className={styles.taskSelector}
              onChange={(value) => setTaskId(value)}
              value={taskId}
            />
        </div>

        <div className={styles.filter}>
            <div className={styles.selectTitle}>Role</div>
            <Select
              options={[{value: 'author', label: 'author'}, {value: 'reviewer', label: 'reviewer'}]}
              className={styles.selector}
              onChange={(value) => setRole(value)}
              value={role}
            />
        </div>
      </div>

      <div>
          <div className={styles.table}>
            {
              role === 'author' ? 
              <Table
                className={styles.table}
                columns={authorColumns}
                dataSource={authorData}
                sticky
                bordered
                loading={loading}
              /> : 
              <Table
                className={styles.table}
                columns={reviewerColumns}
                dataSource={reviewerData}
                sticky
                bordered
                loading={loading}
              />
            }
          
          </div>
      </div>

    </div>
  )
}


export default ViewData;