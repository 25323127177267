import React, { useState, useEffect } from 'react';
import { Table, Radio, Input, Button, Spin, message, Modal } from 'antd';
import styles from './css/Returned.module.scss'
import TabBar from './components/TabBar';
import { useNavigate, useParams } from 'react-router';
import revisionStore from './store/revisionStore';
import { observer } from "mobx-react";
import EInput from './components/EInput';
import { PlusOutlined, CloseCircleOutlined, ArrowRightOutlined } from '@ant-design/icons';
import RevisionCard from './components/RevisionCard';
import { countWords } from './utils/utils';
import fileDownload from 'js-file-download'
import Score from './components/Score';
import ReturnBg from './img/return-bg.png'
import userStore from './store/userStore';
import CommentCard from './components/CommentCard';

function Returned() {

  const navigate = useNavigate()
  const params = useParams()
 
  const { confirm } = Modal;
  
  useEffect(() => {
    revisionStore.fetchTask(params.id)
  }, [])



  const countFormatter = (val) => {
    const cnt = countWords(val.value)
    return <div className={styles.cntWord}>{cnt} words</div>;
  }

  const handlePrevious = () => {
    window.open(`/previous/${revisionStore.id}`, '_blank')
  }

  const myReview = revisionStore.reviews.find(item => item.taskReviewer.name === userStore.profile.name)
  const othersReviews = revisionStore.reviews.filter(item => item.taskReviewer.name !== userStore.profile.name)
  
  return (
    <>
      <TabBar title="Revision Returned"/>
      <Spin spinning={revisionStore.loading}>
        <div className={styles.body}>
          <img src={ReturnBg} className={styles.bg} />
          <div className={styles.left}>
            <div className={styles.segTitle}>
              Revised Version
            </div>
            <div className={styles.seg}>
              <div className={styles.contentTitle}>
                {
                  revisionStore.revision.title 
                }
              </div>
              <div className={styles.content}>
                {
                  revisionStore.revision.content
                }
              </div>
            </div>
            <div className={styles.btnDownload}>
                <Button className={styles.btn} size='large' type="primary" onClick={handlePrevious}>View Previous Version</Button> 
            </div> 
             
          </div>

          <div className={styles.right}>
              <CommentCard showAiScore isMy review={myReview} title="Your previous comments"/>

              <div className={styles.commentTi}>Other Reviewers’ Comments</div>
              {
                othersReviews.map((item, index) => {
                  return (
                    <CommentCard review={item} title={`Other reviewer #${index + 1}`}/>
                  )
                })
              }
          </div>
        </div>
      </Spin>
    </>
  )
}

export default observer(Returned);