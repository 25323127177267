import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { message, Spin } from 'antd';
import { observer } from "mobx-react";

import { getUserTasks } from './utils/request';
import userStore from './store/userStore';
import AuthorIcon from './img/author.png'
import ReviewerIcon from './img/reviewer.png'
import AuthorTaskItem from './components/AuthorTaskItem';

import styles from './css/SelectTask.module.scss';
import TabBar from './components/TabBar';
import ReviewerTaskItem from './components/ReviewerTaskItem';

function SelectTask () {

    const navigate = useNavigate()
    const userProfile = userStore.profile
    const isAdmin = userProfile && userProfile.isAdmin

    const [authorTasks, setAuthorTasks] = useState([])
    const [reviewerTasks, setReviewerTasks] = useState([])

    const [loading, setLoading] = useState(false)


    async function fetchUserTasks() {
        setLoading(true)
        const tasks = await getUserTasks()

        setAuthorTasks(tasks.data.authorTasks)
        setReviewerTasks(tasks.data.reviewerTasks)

        setLoading(false)
    }

    useEffect(() => {
        fetchUserTasks()
    }, [])

    
    const [messageApi, contextHolder] = message.useMessage()

   
    return (
        <div>
            <TabBar />
            <div className={styles.body}>
                <Spin spinning={loading}>
                    <div className={styles.content}>
                        <div className={styles.left}>
                            <div className={styles.title}><img className={styles.icon} src={AuthorIcon}/> Author Tasks</div>
                            {
                                authorTasks.map(item => {
                                    return (
                                        <AuthorTaskItem task={item}/>
                                    )
                                })
                            }
                            {
                                authorTasks.length === 0 && <div className={styles.hint}>No tasks available</div>
                            }
                        </div>
                        <div className={styles.right}>
                            <div className={styles.title}><img className={styles.icon} src={ReviewerIcon}/>Reviewer Tasks</div>
                            {
                                reviewerTasks.map(item => {
                                    return (
                                        <ReviewerTaskItem task={item}/>
                                    )
                                })
                            }
                            {
                                reviewerTasks.length === 0 && <div className={styles.hint}>No tasks available</div>
                            }
                        </div>
                    </div>
                </Spin>
                {contextHolder}
            </div>
        </div>
    )
}

export default observer(SelectTask);
