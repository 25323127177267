import { useState } from 'react';
import { Modal, message } from 'antd'

import { modifyPassword } from './utils/request';

import styles from './css/MyAccount.module.scss';
import logo from './img/logo.png';
import myAccount from './img/my-account.png'
import loginGuide from './img/login-guide.png'
import userStore from './store/userStore';
import { observer } from 'mobx-react';
import Loading from './components/Loading';
import { useNavigate } from 'react-router';

function MyAccount() {

  const [currentPsw, setCurrenPsw] = useState('')
  const [newPsw, setNewPsw] = useState('')
  const [confirmPsw, setConfirmPsw] = useState('')
  const [modal, setOpen] = useState(false)
  const navigate = useNavigate()


  const userProfile = userStore.profile

  if (!userProfile) return <Loading />
  
  const gender = userProfile.gender === 'F' ? 'Female' : 'Male'


  const inputCurrentPws = (e) => {
    setCurrenPsw(e.target.value)
  }

  const inputNewPsw = (e) => {
    setNewPsw(e.target.value)
  }

  const inputConfirmPsw = (e) => {
    setConfirmPsw(e.target.value)
  }

  const changePassword = async () => {
    const result = await modifyPassword({
      currentPassword: currentPsw,
      newPassword: newPsw
    })
    if (result.code == 0 ) {
      localStorage.removeItem('token')
      navigate('/login', { replace: true })
    } else {
        alert(result.msg)
    }
}

  const canChange = currentPsw.length === 8 && newPsw.length === 8 && confirmPsw.length === 8 && newPsw === confirmPsw

  if(!userProfile) { return <Loading /> }  

  return (
    <div className={styles.myAccount}>
      <div className={styles.left}>
          <img src={logo} className={styles.leftLogo} alt="logo" />
          <img  src={myAccount} className={styles.contentImg} />
      </div>

      <div className={styles.right}>
          <img src={loginGuide} className={styles.loginGuide} />
          <div className={styles.rightBox}>
            <div className={styles.displayContent}>
                <div className={styles.infoTitle}>My account</div>
                <div className={styles.infoContent}>
                      <div className={styles.name}>
                          Name: <span className={styles.bold}>{userProfile.name}</span>
                      </div>
                      <div className={styles.gender}>
                          Gender: {gender}
                      </div>
                      <div className={styles.class}>
                          Class: {userProfile.class}
                      </div>
                      <div className={styles.email}>
                          Email: {userProfile.email}
                      </div>
                </div>                
              </div>

              <div className={styles.divider} />

              <div className={styles.inputContent}>
                    <div className={styles.inputTitle}>
                      Change Password
                    </div>
                    <label className={styles.inputBox}>
                        <p className={styles.inputDesc}>
                          Current password
                          <span className={styles.symbol}> *</span>
                          <span>:</span>
                        </p>
                        <input
                          className={styles.inputCurrent}
                          type='password'
                          maxlen={8}
                          value={currentPsw}
                          onChange={inputCurrentPws}
                          onInput={(e)=> {
                            e.target.value = e.target.value.replace(/\D/g,'')
                            e.target.value = e.target.value.slice(0, 8)
                          }}
                        />
                    </label>
                    <label className={styles.inputBox}>
                        <p className={styles.inputDesc}>
                          New password
                          <span className={styles.symbol}> *</span>
                          <span>:</span>
                          </p>
                        <input
                          placeholder='8 numeric (0-9) characters'
                          className={styles.inputNew}
                          type='password'
                          maxLength={8}
                          onChange={inputNewPsw}
                          value={newPsw}
                          onInput={(e)=> {
                            e.target.value = e.target.value.replace(/\D/g,'')
                            e.target.value = e.target.value.slice(0, 8)
                          }}
                        />
                    </label>
                    <label className={styles.inputBox}>
                        <p className={styles.inputDesc}>
                          Confirm new password
                          <span className={styles.symbol}> *</span>
                          <span>:</span>
                        </p>
                        <input
                          className={styles.inputConfirm}
                          type='password'
                          maxLength={8}
                          onChange={inputConfirmPsw}
                          value={confirmPsw}
                          onInput={(e)=> {
                            e.target.value = e.target.value.replace(/\D/g,'')
                            e.target.value = e.target.value.slice(0, 8)
                          }}
                        />
                    </label>
                </div>
              <div className={styles.rightButton}>
                <div className={canChange ? styles.change : styles.disabled} onClick={canChange ? () => setOpen(true) : () => {message.open({
        type: 'warning',
        content: 'Please input the correct password.',
      })}}>Change</div>
                <div className={styles.cancel} onClick={() => window.history.back()}>Cancel</div>
              </div>
          </div>
      </div>

      <Modal
          open={modal}
          centered
          footer={null}
          closable={false}
        >
          <div className={styles.modal}>
            <div className={styles.modalContent}>You will be logged out of your account. Please log in using your new password.</div>
            <div className={styles.modalBtnGroup}>
              <div
                className={styles.modalBtnOk}
                onClick={() => {
                  setOpen(false)
                  if(canChange) {
                    changePassword()
                  }
                }}
              >OK</div>
              <div className={styles.modalBtnCancel} onClick={() => {
                setOpen(false)
                // window.history.back()
              } }>Cancel</div>
            </div>
          </div>
        </Modal>

    </div>
  );
}

export default observer(MyAccount);
