import React, { useState, useEffect } from 'react';
import { Table, Radio, Input, Button, Spin, message, Modal } from 'antd';
import styles from '../src/css/Writting.module.scss'
import TabBar from './components/TabBar';
import { useNavigate, useParams } from 'react-router';
import taskStore from './store/taskStore';
import { observer } from "mobx-react";
import EInput from './components/EInput';
import { PlusOutlined, CloseCircleOutlined, ArrowRightOutlined } from '@ant-design/icons';
import AiHelp from './components/AiHelp';
import { countWords } from './utils/utils';
import fileDownload from 'js-file-download'

function Submission() {

 
  const { TextArea } = Input;
  const { confirm } = Modal;
  const [saveLoading, setSaveLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)

  const disabled = taskStore.submission.submitted
  
  useEffect(() => {

  }, [])

  // const handleDeleteOutline = (index) => {
  //   messageApi.confirm({
  //     title: 'Are you sure to delete this subclaim?',

  //   })
  // }

  const evaluate = () => {
    if(taskStore.aiLoading || taskStore.submission.aiEvaluateLeft <= 0) {
      messageApi.open({
        type: 'warning',
        content: 'You have run out of chances.',
      });
      return
    }
    if (!taskStore.submission.title || !taskStore.submission.content) {
      messageApi.open({
        type: 'warning',
        content: 'Please input title and content',
      });
      return
    }
    taskStore.evaluateSubmission()
  } 

 

  const saveSubmission = async () => {
    setSaveLoading(true)
    await taskStore.saveTaskSubmission()
    messageApi.open({
      type: 'success',
      content: 'Saved successfully',
    });
    setSaveLoading(false)
  }

  const submitSubmission = async () => {
    // 校验
    const words = countWords(taskStore.submission.content)
    if(!taskStore.submission.title) {
      messageApi.open({
        type: 'warning',
        content: 'Please input a title for your essay',
      });
      return
    }
    if (words < 300) {
      messageApi.open({
        type: 'warning',
        content: 'You need to write at least 300 words.',
      });
      return
    }

    // 二次确认
    confirm({
      title: 'Attention',
      icon: null,
      content: 'Are you sure you want to submit your essay? Once submitted, it will be sent to the reviewer.',
      bodyStyle: {
        textAlign: 'center'
      },
      onOk() {
        submit()
      }
    })
  }

  const  submit = async () => {
    setSubmitLoading(true)
    await taskStore.submitTaskSubmission()
    setSubmitLoading(false)
  }

  const countFormatter = (val) => {
    const cnt = countWords(val.value)
    return <div className={styles.cntWord}>{cnt} words</div>;
  }

  const download = () => {
     const downloadContent = taskStore.submission.title + '\n\n' + taskStore.submission.content
      fileDownload(downloadContent, 'My Essay.txt')
  }
      
 
  const [messageApi, contextHolder] = message.useMessage();

  return (
    <div className={styles.body}>
      {contextHolder}
      <div className={styles.content}>
        <div className={styles.seg}>
          <EInput disabled={disabled} width={40} onChange={value => taskStore.submission.title = value} value={taskStore.submission.title} label="Title" placeholder="Please input a title for your essay"/>
        </div>

        <div className={styles.seg}>
          <TextArea disabled={disabled} onChange={(e) => taskStore.submission.content = e.target.value} style={{resize: 'none'}} value={taskStore.submission.content} className={styles.submissionTextArea} bordered={false} showCount={{ formatter: countFormatter }}/>
        </div>
      </div>
     
      <Spin spinning={taskStore.aiLoading}>
        <div className={styles.rightContent}>
          <div className={styles.ai}>
              <AiHelp  showPrompt={window.location.href.indexOf('showPrompt') > 0} showBtns={!disabled} full={false} lBtn={{title: 'I’ve revised my essay. Please evaluate it again.', subTitle: `(${taskStore.submission.aiEvaluateLeft} times left)`, onClick: evaluate}} aiHelps={taskStore.submission.aiHelps}/>
          </div>

          {
            disabled && 
            <div className={styles.hints}>
              <div className={styles.hint}>Your essay has received an AI score of <span className={styles.aiScore}>{taskStore.taskAuthor.aiScore}</span> points <br />(content: {taskStore.contentScore} points; organization: {taskStore.organizationScore} points; language: {taskStore.languageScore} points)</div>
              <div> <Button className={styles.btn} size='large' type="primary" onClick={download}>Download My Essay</Button> </div>
            </div>
          }

          {
            !disabled &&
            <div className={styles.btns}>  
              <Button className={styles.btn} loading={saveLoading} size='large' type="primary" onClick={saveSubmission}>Save</Button>
              <Button className={styles.btn} loading={submitLoading} size='large' type="primary" onClick={submitSubmission}>Submit</Button>
            </div>
          }
        </div>
      </Spin>

     
    </div>
  )
}

export default observer(Submission);