import React, { useState, useEffect } from 'react';
import { Table, Radio, Input, Button, Spin, message, Modal } from 'antd';
import styles from '../src/css/CreateTask.module.scss'
import TabBar from './components/TabBar';
import { useNavigate, useParams } from 'react-router';
import taskProfileStore from './store/taskProfileStore';
import { observer } from "mobx-react";

function CreateTask() {

  const navigate = useNavigate()
  const params = useParams()
  const [topic, setTopic] = useState('')
  const [statement, setStatement] = useState('')
  const [requirement, setRequirement] = useState('')
  const { confirm } = Modal;
  
  useEffect(() => {
   taskProfileStore.fetchUsers(params.class)
  }, [])
      
  const users = taskProfileStore.users

  const { TextArea } = Input;
  const [messageApi, contextHolder] = message.useMessage();

  const authorCount = users && users.filter(item => item.role === 'author').length
  const reviewerCount = users && users.filter(item => item.role === 'reviewer').length

  const next = () => {
    if(!topic) {
      messageApi.open({
        type: 'error',
        content: 'please input the topic',
      });
      return 
    } else if(!statement) {
      messageApi.open({
        type: 'error',
        content: 'please input the statement',
      });
      return 
    }
    else if(!requirement) {
      messageApi.open({
        type: 'error',
        content: 'please input the requirement',
      });
      return 
    }
    const none = users && users.filter(item => item.role === '').length
    
    if (none) {
      messageApi.open({
        type: 'error',
        content: 'please assign the role for all users',
      });
      return 
    }

    // 提交
    confirm({
      title: 'Attention',
      icon: null,
      content: 'The role assignment cannot be modified. Are you sure you want to proceed?',
      bodyStyle: {
        textAlign: 'center'
      },
      onOk() {
        taskProfileStore.submit(topic, statement, requirement)
        navigate('/task-assign')
      }
    })
    
  }

  return (
    <>
      <TabBar title="Task Creation"/>
      {contextHolder}
      <div className={styles.page}>
        <div className={styles.left}>
          <div className={styles.title}>Topic</div>
          <Input placeholder="please input the topic" onChange={e => setTopic(e.target.value)}/>

          <div className={styles.title}>Task Instruction</div>
          <div className={styles.contentWrapper}>

            <div className={styles.state}>Statement</div>
            <TextArea
              bordered={false} 
              className={styles.textarea}
              placeholder="please input the statement"
              rows={4}
              onChange={e => setStatement(e.target.value)}
            />

            <div className={styles.state}>Requirement</div>
            <TextArea
              bordered={false} 
              className={styles.textarea}
              placeholder="please input the requirement"
              rows={14}
              onChange={e => setRequirement(e.target.value)}
            />

          </div>
        </div>
        
        <div className={styles.right}>
          <div className={styles.title}>Role Assignment</div>
          
          <div className={styles.table}>
            <div className={styles.head}>
              <div>Name</div>
              <div>Author({authorCount})</div>
              <div>Reviewer({reviewerCount})</div>
            </div>
            <Spin spinning={taskProfileStore.loading}>
            {
              users && users.map((user, index) => {
                return <div className={styles.row}>
                    <div className={styles.name}>
                    {index+1}. {user.name}
                    </div>
                    <Radio value="author" className={styles.radio} checked={user.role === 'author'} onClick={() => {
                      taskProfileStore.setRole(index, 'author')
                    }}></Radio>
                    <Radio value="reviewer" className={styles.radio} checked={user.role === 'reviewer'}  onClick={() => {
                      taskProfileStore.setRole(index, 'reviewer')
                    }}></Radio>
                  </div>
              })
            }
            </Spin>
          </div>
        </div>

       
      </div>
      <div className={styles.bottom}>
          <Button type="primary" className={styles.btn} onClick={next}>Next</Button>
      </div>
    </>
  )
}

export default observer(CreateTask);