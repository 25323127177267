import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import  { Popover, Modal } from 'antd';
import { observer } from "mobx-react";

import userStore from '../store/userStore';
import selectIssueStore from '../store/selectIssueStore';
import styles from '../css/TabBar.module.scss';
import Icon from '../img/favicon.png'
import ShowPoints from './ShowPoints';
import Loading from './Loading';
import Logo from '../img/tabbar-logo.png'

function TabBar(props) {
    
    useEffect(() => {
      userStore.refresh()
    }, [])

    const [openModal, setOpenModal] = useState(false)

    const navigate = useNavigate()
  
  
    const showModal = () => {
      setOpenModal(true)
    }
    
    const hideModal = () => {
      setOpenModal(false)
    }


    const logout = () => {
      localStorage.removeItem('token')
      navigate('/login')
    }

    const viewData = () => {
      navigate('/admin-view-data')
    }

    const manageUser = () => {
      navigate('/admin-manage-user')
    }

    const goCRM = () => {
      navigate('/admin-crm')
    }

    const { currentTab, hideMenu, title, subTitle } = props
    const selectIssue = selectIssueStore.selectIssue
    const userProfile = userStore.profile


    if (!userProfile) return <Loading />

    return (
      <div className={styles.body}>
        
        <div className={styles.left}>
          <img src={Logo} className={styles.leftLogo} alt="logo" />
          {
            title && 
            <div className={styles.title}>
              {title}
            </div>
          }
          <div className={styles.subTitle}>
            {subTitle}
          </div>
          
        </div>

        <div className={styles.right}>
          {hideMenu ? null: 
              <div className={styles.button} onClick={showModal}>
                Leaderboard
              </div>
          }
          <Popover
              content={
                <div>
                  <Link to='/my-account'><div className={styles.myAccount}>My account</div></Link>
                  {
                    userProfile.isAdmin &&
                    <div>
                      <div className={styles.divider} />
                      <div className={styles.logOut} onClick={goCRM}>CRM</div>
                      <div className={styles.divider} />
                      <div className={styles.logOut} onClick={viewData}>View data</div>
                      <div className={styles.divider} />
                      <div className={styles.logOut} onClick={manageUser}>Manage users</div>
                    </div>
                  }
                  <div className={styles.divider} />
                  <div className={styles.logOut} onClick={logout}>Log out</div>
                </div>
              }
              arrow={false}
              trigger="click"
              placement="bottomRight"
              overlayClassName={styles.pop}
            >
            <div className={styles.userInfo}>
                <div className={styles.userName}>{userStore.profile.name}</div>
            </div>
          </Popover>
        </div>
  
        <Modal
          open={openModal}
          centered
          maskClosable={true}
          width={'55vw'}
          destroyOnClose={true}
          style={{pointerEvents: 'auto', top: 20, marginBottom: 40}}
          modalRender={() => {
            return(<ShowPoints hideModal={() => hideModal}/>)
          }}
        >
        </Modal>

      </div>
    )

  
}


export default observer(TabBar);