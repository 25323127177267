import React from 'react'
import styles from '../css/RevisionCard.module.scss'
import ReviewerTag from '../img/reviewer-tag.png'
import { Input } from 'antd'
import Score from './Score'
import { observer } from 'mobx-react'

const { TextArea } = Input

 class RevisionCard extends React.Component {

  constructor(props) {
    super(props)
  }


  render() {
    const { review, index, disabled } = this.props

    return (
      <div className={styles.card}>

        <img className={styles.tag} src={ReviewerTag} />
        <div className={styles.title}>Reviewer {index + 1}</div>
      
        <div className={styles.content}>

          <div className={styles.scores}>
            <div>Content: {review.contentScore} points</div>
            <div>Organization: {review.organizationScore} points</div>
            <div>Language: {review.languageScore} points</div>
          </div>

          <div className={styles.commentTitle}>Comment:</div>
          <div className={styles.comment}>{review.comment}</div>

          <div className={styles.revision}>
            <div>
              <div className={styles.rspTitle}>Is this reviewer’s feedback helpful? </div>
              <div className={styles.helpful}>(1 = very unhelpful; 5 = very helpful)</div>
            </div>
            <Score  disabled={disabled}  score={review.authorScore || 0} onChange={val => review.authorScore = val} />
          </div>

          <div className={styles.rsp}>
          Your response to the reviewer’s feedback:
          </div>

          <TextArea disabled={disabled}  value={review.authorResponse} onChange={e => review.authorResponse = e.target.value} rows={6} bordered={false} className={styles.txt} placeholder="Clarify the changes you've made in response to the reviewer’s feedback. If you have any disagreements with their comments, provide a clear and logical justification."></TextArea>
        </div>
      </div>
    )
  }
  
}

export default observer(RevisionCard);