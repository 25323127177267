import React, { useState, useEffect } from 'react';
import { Table, Radio, Input, Button, Checkbox, message, Modal, Select } from 'antd';
import styles from '../src/css/TaskAssign.module.scss'
import TabBar from './components/TabBar';
import { useNavigate, useParams } from 'react-router';
import taskProfileStore from './store/taskProfileStore';
import { observer } from "mobx-react";
import { PlusOutlined } from '@ant-design/icons';
import deleteBtn from './img/delete-btn.png'

function TaskAssign() {

  const navigate = useNavigate()
  const params = useParams()
  const { confirm } = Modal;
  const [assignIndex, setAssignIndex] = useState(0)
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedReviewers, setSelectedReviewers] = useState([])
  const [loading, setLoading] = useState(false)
  const assignment = taskProfileStore.assignment

  const { TextArea } = Input;
  const [messageApi, contextHolder] = message.useMessage();

  const totalReviewers = taskProfileStore.users.filter(item => item.role === 'reviewer')
  const reviewerCount = totalReviewers.length

  const handleChange = (value) => {
    taskProfileStore.autoAssign(value)
  }

  const openAdd = (assignIndex) => {
    setAssignIndex(assignIndex)

    const selectedAssign = assignment[assignIndex]
    setSelectedReviewers(selectedAssign.reviewers)

    setModalOpen(true)
  }

  const changeChecked = (e, reviewer) => {
    if(e.target.checked) { 
      setSelectedReviewers([...selectedReviewers, reviewer])
    } else {
      setSelectedReviewers(selectedReviewers.filter(item => item.id !== reviewer.id))
    }
  }  

  const handleCancel = () => {
    setModalOpen(false)
  }

  const handleOK = () => {
    taskProfileStore.setAssign(assignIndex, selectedReviewers)
    setModalOpen(false)
  }

  const selectedAssign = assignment[assignIndex]
  const countMap = taskProfileStore.countMap

  const handleConfirm = async () => {
    setLoading(true)
    const result = await taskProfileStore.commit()
    setLoading(false)
    messageApi.open({
      type: 'success',
      content: 'Created successfully.',
    });
    navigate(-2)
  }

  return (
    <>
      <TabBar title="Reviewer Task Management"/>
      {contextHolder}
      <div className={styles.page}>
        <div className={styles.center}>
          <div className={styles.title}>
            <div>Reviewer Assignment</div>
            <div>
            <Select
              placeholder="Auto Assign"
              style={{ width: 200 }}
              onChange={handleChange}
              options={[
                { value: '1', label: '1 reviewer / essay' },
                { value: '2', label: '2 reviewers / essay', disabled: reviewerCount < 2 },
                { value: '3', label: '3 reviewers / essay',   disabled: reviewerCount < 3 },
                { value: '4', label: '4 reviewers / essay' , disabled: reviewerCount < 4},
                { value: '5', label: '5 reviewers / essay', disabled: reviewerCount < 5}
              ]}
            />
            </div>
            
          </div>
          <div className={styles.content}>
              <div className={styles.head}>
                <div>Author Name</div>
                <div>Reviewer Name</div>
              </div>

              {
                assignment.map((assign, assignIndex) => {
                  return (
                    <div className={styles.row} key={assignIndex}>
                      <div className={styles.author}>
                        <div>{assign.author.name}</div>
                      </div>

                      <div class={styles.reviewers}>
                        {
                          assign.reviewers.map((reviewer, index) => {
                            return (
                              <div key={index} className={styles.reviewer}>
                                <div>{reviewer.name}</div>
                                <img onClick={() => taskProfileStore.delete(assignIndex, index)} src={deleteBtn} className={styles.deleteBtn} />
                              </div>
                            )
                          })
                        }
                        <Button onClick={() => { openAdd(assignIndex) }} className={styles.addBtn} type="primary" ghost icon={<PlusOutlined />}>Add</Button>
                      </div>
                    </div>
                  )
                })
              }


          </div>
          
        </div>
        
      </div>

      <Modal
        title={<div style={{textAlign: 'center'}}>Add Reviewers</div>}
        open={modalOpen}
        destroyOnClose
        onCancel={handleCancel}
        footer={<div style={{ display: 'flex', justifyContent:'center' }}><Button style={{width: 100}} type="primary" onClick={handleOK}>OK</Button></div>}
      >
        <div>Author Name: {selectedAssign.author.name} </div>
        <div className={styles.modal}>
              {
                totalReviewers.map((reviewer, index) => {
                  return(
                    <div>
                      <Checkbox onChange={e => changeChecked(e, reviewer)}  className={styles.checkbox} checked={ !!selectedReviewers.some(item => item.id===reviewer.id) }/>
                      { reviewer.name } (now reviews {countMap[reviewer.id]} essays)
                    </div>
                  )
                })
              }
        </div>
      </Modal>
      <div className={styles.bottom}>
          <Button type="primary" loading={loading} className={styles.btn} onClick={handleConfirm}>Confirm</Button>
      </div>
    </>
  )
}

export default observer(TaskAssign);