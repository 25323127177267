import React from 'react';
import { Input } from 'antd';
import styles from '../css/EInput.module.scss'


export default (props) => {
    const {label, placeholder, onChange, value, width, disabled} = props

    const changeValue = (e) => {
        onChange && onChange(e.target.value)
    }
    return (
        <div className={styles.wrapper}>
            <span className={styles.label} style={{ width: width + 'px', minWidth: width + 'px'}}>{label}</span>
            <Input disabled={disabled} value={value} onChange={changeValue} className={styles.input} placeholder={placeholder} bordered={false} />
        </div>
    )
}
