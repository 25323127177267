import { makeAutoObservable } from "mobx";
import { create, persist } from 'mobx-persist'

// Todo 總數據源
class selectIssueStore {
  selectIssue = null

  constructor() {
    makeAutoObservable(this);
  }

}

const schema = {
  selectIssue: true
}

// const selectIssue = new selectIssueStore();

const hydrate = create({
  storage: localStorage,   // or AsyncStorage in react-native.
                          // default: localStorage
  jsonify: true  // if you use AsyncStorage, here shoud be true
                  // default: true
})

const selectIssue = persist(schema)(new selectIssueStore())

hydrate('selectIssue', selectIssue).then(() => console.log('selectIssue has been hydrated'))

export default selectIssue;
