import React from 'react'
import styles from '../css/Score.module.scss'

export default class Score extends React.Component {

  constructor(props) {
    super(props)
  }

  handleClick = (score) => {
    const { onChange, disabled } = this.props
    !disabled && onChange && onChange(score)
  }

  render() {
    const { score } = this.props
    const arr = [1,2,3,4,5]

    return (
      <div className={styles.group}>
        {
          arr.map(item => {
            return (
              <div className={[styles.score, item <= Number(score) ? styles.active : ''].join(' ')} onClick={() => { this.handleClick(item)}}>
                {item}
              </div>
            )
          })
        }
       
      </div>
    )
  }
  
}
