import React, { useState, useEffect } from 'react';
import { Table, Radio, Input, Button, Spin, message, Modal } from 'antd';
import styles from '../src/css/Review.module.scss'
import TabBar from './components/TabBar';
import { useNavigate, useParams } from 'react-router';
import reviewStore from './store/reviewStore';
import { observer } from "mobx-react";
import EInput from './components/EInput';
import { PlusOutlined, CloseCircleOutlined, ArrowRightOutlined } from '@ant-design/icons';
import AiHelp from './components/AiHelp';
import { countWords } from './utils/utils';
import fileDownload from 'js-file-download'
import taskStore from './store/taskStore';
import userStore from './store/userStore';
import Score from './components/Score';
import ReviewAiHelp from './components/ReviewAiHelp';

function Review() {

 
  const { TextArea } = Input;

  const disabled = reviewStore.review.submitted

  const navigate = useNavigate()
  const params = useParams()
 
  const { confirm } = Modal;
  
  useEffect(() => {
    reviewStore.fetchTask(params.id)
  }, [])

  // const handleDeleteOutline = (index) => {
  //   messageApi.confirm({
  //     title: 'Are you sure to delete this subclaim?',

  //   })
  // }

  const evaluate = () => {
    if(reviewStore.aiLoading || reviewStore.review.aiEvaluateLeft <= 0) {
      messageApi.open({
        type: 'warning',
        content: 'You have run out of chances.',
      });
      return
    }

    const words = countWords(reviewStore.review.comment)

    if (words < 20) {
      messageApi.open({
        type: 'warning',
        content: 'Please input at least 20 words.',
      });
      return
    } else if (words > 200) {
      messageApi.open({
        type: 'warning',
        content: 'Please write at most 200 words.',
      });
      return
    }

    reviewStore.evaluateComment()
  } 

 
  const submitComment = async () => {
    // 校验
    const words = countWords(reviewStore.review.comment)
    
    if (!reviewStore.review.contentScore || !reviewStore.review.organizationScore || !reviewStore.review.languageScore) {
      messageApi.open({
        type: 'warning',
        content: 'Please evaluate the essay.',
      });
      return
    }

    if (words < 20) {
      messageApi.open({
        type: 'warning',
        content: 'You need to comment at least 20 words.',
      });
      return
    }

    if (words > 200) {
      messageApi.open({
        type: 'warning',
        content: 'You need to comment at most 200 words.',
      });
      return
    }
   
    // 二次确认
    confirm({
      title: 'Attention',
      icon: null,
      content: 'Are you sure you want to submit your evaluation? Once submitted, it will be sent to the author.',
      bodyStyle: {
        textAlign: 'center'
      },
      onOk() {
        submit()
      }
    })
  }

  const  submit = async () => {
    await reviewStore.submitComment()
  }

  const countFormatter = (val) => {
    const cnt = countWords(val.value)
    return <div className={[styles.cntWord, cnt > 200? styles.cntError: ''].join(' ')}>{cnt} words</div>;
  }
 
  const [messageApi, contextHolder] = message.useMessage();

  return (
    <>
      <TabBar title={reviewStore?.review?.submitted ? "Review Completed" : "Awaiting Review"} />
      <Spin spinning={reviewStore.loading}>
        <div className={styles.body}>
          {contextHolder}
          <div className={styles.left}>
            <div className={styles.segTop}>
              <div className={styles.midTitle}>Topic: {reviewStore.taskProfile.topic}</div>

              <div className={styles.title}>Task Instruction: </div>
              <div>{reviewStore.taskProfile.statement}{reviewStore.taskProfile.requirement}</div>
            </div>
            <div className={styles.seg}>
              <div className={styles.contentTitle}>
                {
                  reviewStore.submission.title 
                }
              </div>
              <div className={styles.content}>
                {
                  reviewStore.submission.content
                }
              </div>

              
            </div>
          </div>

          <div className={styles.right}>
                <div className={styles.hint}>

                  <div className={styles.title}>
                    How to evaluate an essay?
                  </div>

                  <div className={styles.subTitle}>
                    Aspect 1: Content
                  </div>
                  <div className={styles.def}>
                  Definition: The extent to which the writer presents a clear and convincing argument, supports it with evidence, and addresses counterarguments.
                  </div>
                  <div className={styles.hintContent}>
                    1 point (Inadequate): The argument is unclear or missing. The writer fails to provide sufficient evidence to support the argument. The writer does not address counterarguments.
                    <br />2 points (Developing): The argument is somewhat clear but lacks coherence or consistency. The writer provides some evidence but does not use it effectively to support the argument. The writer acknowledges counterarguments but fails to address them adequately.
                    <br />3 points (Proficient): The argument is clear, logical, and convincing. The writer provides sufficient evidence to support the argument and uses it effectively. The writer addresses counterarguments, but may not fully refute them.
                    <br />4 points (Accomplished): The argument is highly convincing and effectively demonstrates the writer's point of view. The writer provides strong evidence to support the argument and persuasively uses it. The writer addresses counterarguments and effectively refutes them.
                    <br />5 points (Exemplary): The argument is exceptionally clear, compelling, and sophisticated. The writer provides compelling and varied evidence to support the argument, and effectively integrates it into the essay. The writer addresses counterarguments and successfully refutes them with strong evidence and reasoning.
                  </div>

                  <div className={styles.subTitle}>
                  Aspect 2: Organization
                  </div>
                  <div className={styles.def}>
                  Definition: The extent to which the writer structures the essay in a coherent and logical manner, and effectively transitions between ideas.
                  </div>
                  <div className={styles.hintContent}>
                  1 point (Inadequate): The essay lacks any discernible structure or coherence. There are no clear transitions between ideas or paragraphs.
                  <br />2 points (Developing): The essay has some structure, but it is not consistent or effective. Transitions between ideas or paragraphs are present, but not always clear or effective.
                  <br />3 points (Proficient): The essay is well-structured and coherent, with a clear introduction, body, and conclusion. Transitions between ideas or paragraphs are effective and contribute to the overall coherence of the essay.
                  <br />4 points (Accomplished): The essay is exceptionally well-structured and coherent, with a clear and engaging introduction, well-developed body paragraphs, and a strong conclusion. Transitions between ideas or paragraphs are seamless and contribute significantly to the overall coherence of the essay.
                  <br />5 points (Exemplary): The essay is masterfully structured and coherent, with a compelling and sophisticated introduction, seamlessly integrated body paragraphs, and a powerful conclusion. Transitions between ideas or paragraphs are flawlessly executed and contribute to the overall impact and effectiveness of the essay.
                  </div>

                  <div className={styles.subTitle}>
                  Aspect 3: Language
                  </div>
                  <div className={styles.def}>
                  Definition: The extent to which the writer uses appropriate, precise, and varied language, and demonstrates control over grammar, syntax, and mechanics.
                  </div>
                  <div className={styles.hintContent}>
                  1 point (Inadequate): The language is unclear or inappropriate, making the essay difficult to understand. The grammar, syntax, and mechanics are consistently incorrect or ineffective.
                  <br />2 points (Developing): The language is somewhat appropriate and varied, but lacks precision or coherence. The grammar, syntax, and mechanics are inconsistent or contain errors that affect comprehension.
                  <br />3 points (Proficient): The language is mostly appropriate, precise, and varied, and effectively conveys the writer's ideas. The grammar, syntax, and mechanics are generally correct and do not significantly impede comprehension.
                  <br />4 points (Accomplished): The language is highly effective in conveying the writer's ideas, and demonstrates a sophisticated use of vocabulary and syntax. The grammar, syntax, and mechanics are consistently correct, and enhance the clarity and impact of the essay.
                  <br />5 points (Exemplary): The language is exceptionally effective, demonstrating a mastery of vocabulary, syntax, and rhetorical devices that enhance the essay's impact. The grammar, syntax, and mechanics are virtually flawless, contributing to the essay's exceptional clarity and effectiveness.
                  </div>
                </div>

                <div className={styles.operation}>
                  <div className={styles.div}>
                    <div className={styles.opLeft}>
                      <div className={styles.title}>
                        Evaluate this essay
                      </div>
                      <div className={styles.scores}>
                        <div className={styles.scoreWrapper}>
                          <div className={styles.name}>Content</div>
                          <div><Score disabled={disabled} score={reviewStore.review.contentScore} onChange={val => reviewStore.review.contentScore = val}/></div>
                        </div>

                        <div className={styles.scoreWrapper}>
                          <div className={styles.name}>Organization</div>
                          <div><Score disabled={disabled} score={reviewStore.review.organizationScore} onChange={val => reviewStore.review.organizationScore = val}/></div>
                        </div>

                        <div className={styles.scoreWrapper}>
                          <div className={styles.name}>Language</div>
                          <div><Score disabled={disabled} score={reviewStore.review.languageScore} onChange={val => reviewStore.review.languageScore = val}/></div>
                        </div>
                      </div>

                      <div>
                        <div className={styles.commentTitle}>Comment:</div>
                        <TextArea
                          placeholder='Please input at least 20 words. When providing comments, please avoid simply copying and pasting the descriptors from the rating rubric. Your peers would benefit from personalized feedback that is specific to their work.'
                          disabled={disabled}
                          value={reviewStore.review.comment}
                          onChange={e => reviewStore.review.comment = e.target.value }
                          autoSize={{ minRows: 8, maxRows: 12 }}
                          bordered={false}
                          className={styles.textarea}
                          showCount={{ formatter: countFormatter}}
                        />
                      </div>

                      {
                        !disabled &&
                        <div className={styles.btnWrapper}>
                          <Button type='primary' loading={reviewStore.submitLoading} onClick={submitComment}>Submit</Button>
                        </div>
                      }
                      
                    </div>

                    <div className={styles.opRight}>
                      <Spin spinning={reviewStore.aiLoading}>
                        <ReviewAiHelp aiHelps={reviewStore.review.aiHelps} showBtns={!disabled && !userStore.profile.aiDisabled} lBtn={{title: 'Check my comments', subTitle: `(${reviewStore.review.aiEvaluateLeft} times left)`, onClick: evaluate}} />
                      </Spin>
                    </div>

                  </div>
                </div>
                {
                  disabled && !userStore.profile.aiDisabled &&
                  <div className={styles.aiHints}>
                    <div>
                      <div style={{textAlign: 'center'}}>Your comments have received an AI score of <span className={styles.aiScore}>{reviewStore.review.totalScore}</span> points </div>
                      (affect: {reviewStore.review.affectScore} points; description: {reviewStore.review.descriptionScore} points; identification: {reviewStore.review.identificationScore} points; justification: {reviewStore.review.justificationScore} points; constructiveness: {reviewStore.review.constructivenessScore} points)
                    </div>
                  </div>
                }
          </div>
        </div>
      </Spin>
    </>
  )
}

export default observer(Review);